.financial-states {
  .white-font {
    color: #fff;
  }
  a.doc-link {
    color: #fff;
  }
  .doc-link:hover {
    color: darken(#fff, 10%);
  }
}
