.product__viewer {
  display: flex;
  .row {
    width: 100%;
  }
  .viewer__container {
    border: 1px solid #eee;
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 523px;
      margin: 0;
      img {
        max-height: 523px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .viewer__carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &-list {
      display: flex;
      flex-direction: row;
      margin-left: 0;
      margin-bottom: 15px;
      padding-bottom: 17px;
      overflow: hidden;
      transition: none;
      width: 100%;
      &:hover {
        overflow-x: auto;
        padding-bottom: 0;
      }
      li {
        margin: 15px 8px;
        border: $gray-200 solid 1px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .product-gallery-item {
          display: block;
          img {
            width: 85px;
            height: 85px;
            object-fit: contain;
          }
        }
      }
    }
  }
  .badge {
    @include m(feature) {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 0px;
      background: none;
      padding: 8px 6px;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: solid 49px #FF9800;
        border-left: solid 40px transparent;
      }
      i {
        position: relative;
        z-index: 9;
        margin-left: 14px;
      }
    }
  }
  .wishlist {
    @include m(like) {
      position: absolute;
      right: 15px;
      bottom: 15px;
      .like-icon {
        color: $red;
        font-size: 18px;
        opacity: 0.7;
        &.isFaved {
          opacity: 1;
        }
      }
    }
  }
}
