.box__form .formulario-asistencia label {
   font-size: .8em;
   margin-bottom: 2px;
}
.box__form .formulario-asistencia{
   padding: 0 30px 20px;
   border-color: #eaeaea;
}
.box__form .formulario-asistencia legend {
   background: $color-base;
   padding: 5px 20px;
   color: #fff;
   font-weight: bold;
}