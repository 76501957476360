.product__tabs {
  display: block;
  .nav-tabs {
    display: flex;
    border: none;
    width: 100%;
    flex-direction: row;
    .nav-item {
      .nav-link {
        font-family: $font-family--title;
        font-size: .8em;
        font-weight: 700;
        text-transform: uppercase;
        border: none;
        margin-right: 0;
        border-bottom: 1px dotted $gray-400;
        border-bottom: none;
        padding: 1em;
        width: max-content;
        min-height: 40px;
        color: $color-base;
        background: transparent;
        &:hover {
          color: white;
          background: $base-darken;
          box-shadow: inset 0 -3px 0 $alternative;
        }
        &:empty {
          display: none;
        }
      }
      &.active {
        .nav-link {
          border: none;
          border-radius: 4px 4px 0 0;
          background: $color-base;
          color: $white;
          box-shadow: inset 0 -3px 0 $alternative;
        }
      }
    }
  }
  .tab-content {
    background-color: white;
    border: 1px solid #ddd;
    padding: 1.5em;
    max-height: 18em;
    flex-grow: 1;
    overflow-y: auto;
    min-height: auto;
    text-align: justify;
    .tab-pane {
      .products-tabs-content {
        .table {
          margin-bottom: 0px;
          thead {
            tr th {
            }
          }
          tbody {
            tr td {
              vertical-align: middle;
              .nombre-producto {
                color: $color-base;
                &:hover {
                  color: darken($color-base, 20%);
                }
              }
            }
          }
        }
        #wrapper-detail-description{
          font-size: .9em;
        }
      }
    }
    .videos {
      .list-unstyled {
        .list-item {
          display: block;
          &:nth-child(even) {
            background: $gray-100;
          }
          &:nth-child(odd) {
            border-top: 1px solid $gray-100;
          }
          &:last-child {
            border-bottom: 1px solid $gray-100;
          }
          a {
            color: $gray-800;
            border: none;
            display: block;
            text-align: left;
            &:hover {
              color: $text-color;
              background-color: $gray-200;
            }
          }
        }
      }
    }
    .url {
      .list-unstyled {
        .list-item {
          display: block;
          &:nth-child(even) {
            background: $gray-100;
          }
          &:nth-child(odd) {
            border-top: 1px solid $gray-100;
          }
          &:last-child {
            border-bottom: 1px solid $gray-100;
          }
          a {
            color: $gray-800;
            border: none;
            display: block;
            text-align: left;
            &:hover {
              color: $text-color;
              background-color: $gray-200;
            }
          }
        }
      }
    }
  }

}
