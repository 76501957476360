// CARRUSEL DE CATEGORIAS DEL HOME
.carousel__grid {
  &.category {
    .carousel-controls {
      &.categories_btns {
        height: 100%;
        padding: 0;
        z-index: 1;
        background: transparent;
        align-items: center;
        width: 50px;
        top: 0;
        &.next {
          right: 0;
        }
        &.prev {
          left: 0;
        }
        .carousel-btn {
          border-radius: 0;
          border: none;
          font-size: 1.3em;
        }
      }
    }
    .grid-category__items {
      .category__items {
        &-title {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          background: rgba($color-base, .9);
          margin: 0;
          padding: .5em 0;
          color: white;
          z-index: 1;
        }
      }
      .category__item {
        .item__bg {
          &--hover {
            background: rgba($color-base, 0.78);
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 0;
            transition: all cubic-bezier(0.22, 0.61, 0.36, 1) .2s;
            i {
              color: #fff;
              font-size: 2em;
              opacity: 0;
            }
          }
        }
        &:hover {
          .item__bg {
            &--hover {
              height: 100%;
              i {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

