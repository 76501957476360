.templates-list{
  font-size: .9em;
  table{
    tbody{
      tr{
        td{
          vertical-align: middle;
          &.acciones{
            text-align: center;
          }
        }
      }
    }
  }
  .barra-mis-cotizaciones {
    margin-top: 10px;
    #filtros-top-cotizacion {
      margin: 15px 0 30px;
      font-size: .9em;
      .toolbox-filter {
        position: relative;
        .search-cotizacion input {
          border: 1px solid #e4e2e2;
          border-radius: 2px;
          padding: 4px;
        }
        #buscar-filtrar {
          position: relative;
          z-index: 1;
          left: -25px;
          color: #b7b7b7;
          width: 0;
        }
        .separator {
          margin-right: 10px;
        }
      }
    }
    .pagination {
      font-size: 0.8em;
      margin: 0;
      li {
        display: inline;
        a {
          margin-left: 0;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }

    .filter-resume {
      position: relative;
      top: 5px;
      font-size: 0.8em;
    }


  }
  .icon-block{
    display: block;
  }
  .text-small{
    font-size: 10px;
  }
}
label  {
  font-weight: 400;
  margin: 0;
  font-size: 12px;
}

span {
  text-align: left;
}
.select-dropdown {

  .dropdown-menu {
    z-index: 9999;
    min-width: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.25);
    box-shadow: 0 3px 6px rgba(0,0,0,0.25);
    font-size: 14px;
    li {
      a {
        display: block;
        padding: 8px 18px;
        font-size: 13px;
        line-height: 1.5;
        color: #737373;
        background: transparent;
      }
    }

  }
  .dropdown-toggle {
    background: #f2f2f2;
    color: #a7a7a7;
    font-size: 18px;
    padding: 4px 10px;
    line-height: 20px;
    border-color: #e4e2e2;
  }
  .select-btn {
    padding: 3px 10px 0px;
    line-height: 20px;
    color: #757575;
    border-color: #e4e2e2;
    border-radius: 2px;
    background: transparent;
    text-align: left;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    a{
      color: #565656;
      font-size: .9em;
    }
    label{
      display: flex;
    }
    .caret{
      margin-left: 5px;
    }
  }
}
.btn:focus, .btn:active {
  outline: none !important;
}