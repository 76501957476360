.page__offline {
   padding: 2em;
   border: 1px solid #ddd;
   margin: 2em;
   .offline__title {
      color: $color-base;
   }
   .offline__countdown {
      position: relative;
      width: 75%;
      margin: 30px auto;
      padding: 15px 0 20px 0;
      color: $color-base;
      border: 1px solid #e4e4e4;
      border-width: 1px 0;
      overflow: hidden;
      font-weight: bold;
      li {
         padding: 0;
         display: inline-block;
         width: 24%;
         text-align: center;
         line-height: 1;
         .label {
            color: #9E9E9E;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 500;
         }
         .number {
            font-size: 64px;
            line-height: .9;
            margin-bottom: 6px;
         }
      }
   }
}