.page-b2b-dashboard {
  .info__aside {
    .title__aside {
      color: white;
      padding: 1em;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 0;
      background: $color-base;
    }
    .text_aside {
      padding: 1em;
      h3 {
        font-size: 1.2em;
        margin-top: 0;
      }
      .contenido__aside {
        .num {
          display: inline-block;
          width: 25px;
          margin-right: 5px;
          text-align: center;
          color: $white;
          border-radius: 50%;
          height: 25px;
          line-height: 25px;
          font-weight: bold;
          background: $alternative;
        }
      }
    }
  }
  .contenido__dashboard {
    .tabla__productos {
      .listado-carga-rapida {
        .ultimos__comprados {
          tbody {
            tr {
              td {
                vertical-align: middle;
                a {
                  text-decoration: none;
                  &:hover {
                    color: $base-darken;
                  }
                }
                .nombre-producto{
                  color: $color-base;
                }
                .custom-quantity-input {
                  margin-top: 0;
                }
                .btn-default {
                  background-color: transparent;
                }
                .add-cgrapida {
                  padding: 7px 10px;
                  margin: 6px 4px;
                  position: relative;
                  top: 2px;
                  border: 0;
                }
                .producto-favorito {
                  color: white;
                  background: $red;
                  border-bottom: $red;
                }
              }
            }
          }
        }
      }
    }
    .estadisticas-cajas {
      .small-box {
        margin-bottom: 15px;
        overflow: hidden;
        h3 {
          margin: 0;
          white-space: nowrap;
          padding: 0;
          text-shadow: 1px 1px 4px rgba(49, 49, 49, 0.38);
        }
        h4 {
          margin-bottom: 0px;
        }
        a {
          text-align: right;
          color: white;
          display: block;
          background: rgba(0, 0, 0, 0.1);
          text-decoration: none;
          padding: 3px 12px;
          position: relative;
          &:hover{
            color: rgba($color: white, $alpha: 0.9);
          }
        }
      }
      .inner {
        padding: 10px 10px 5px;
      }
      .icon {
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        position: absolute;
        top: -10px;
        right: 25px;
        z-index: 0;
        font-size: 70px;
        color: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
