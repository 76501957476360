.breadcrumb__container {
    margin-bottom: 1em;
    .breadcrumb {
        margin-bottom: 0;
        .breadcrumb-item {
            font-size: .8em;
            a {
                color: #626265;;
            }
        }
    }
}