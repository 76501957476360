.shipping__config {
  display: flex;
  background: darken($white, 3%);
  .shipping__box {
    padding: .5em 1.5em;
    background: darken($white, 3%);
    border-right: 1px solid darken($white, 10%);
    &-inner {
      .alert.small {
        margin: .5em 0;
        padding: .5em 1em;
      }
    }
  }
}

