.filter__products {
  .widget__products {
    .widget__title {
      padding: 1em;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .widget__title-icon {
        font-size: 18px;
        position: absolute;
        right: 10px;
        padding: 7px;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .widget__filter {
    padding: 1em;
    .filter__input-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: .7em 0;
      .filter__input {
        width: 100%;
        padding: .6em;
        border: 1px solid $gray-300;
        color: $gray-500;
        border-radius: 3px;
      }
    }
  }
  .filter__tags-container {
    margin: .8em 0;
    line-height: 1.3;
    .filter__tag {
      display: inline-block;
      margin: .1em 0;
      padding: .5em;
      font-weight: 500;
      font-size: .8em;
      white-space: unset;
    }
  }
  .filter__accordion {
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
    .filter__accordion-header {
      padding:.8em 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      &:hover{
        background: rgba($gray-500, .2);
      }
      .filter__accordion-title {
        .filter__accordion-link {
          color: $text-color;
        }
        + .filter__accordion-btn {
          &:after {
            font-family: FontAwesome;
            font-size: 1.33333333em;
            font-weight: 500;
            line-height: .75em;
            color: $gray-400;
          }
        }
        &.isClosed + .filter__accordion-btn:after {
          content: "\f105";
        }
        &.isOpened + .filter__accordion-btn:after {
          content: "\f107";
        }
      }
      .filter__accordion-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: .3em;
      }
    }
    .filter__accordion-body {
      padding: 0px;
      .filter__list {
        margin-bottom: 0;
        .filter__list-item {
          border-bottom: 1px dashed $gray-400;
          padding: .8em 0;
          &:hover{
            background: rgba($gray-500, .2);
          }
          a {
            color: $gray-700;
            font-size: 13px;
          }
          .form__check {
            display: flex;
            position: relative;
            padding-left: 26px;
            user-select: none;
            .widget__check-label {
              color: $gray-500;
              .widget__check-input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 4px;
                top: 0;
                z-index: 1;
                &:checked ~ .widget__checkmark:after {
                  display: block;
                }
              }
              .widget__checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 18px;
                width: 18px;
                background-color: $gray-300;
                border: 2px solid $gray-400;
                border-radius: 50%;
                cursor: pointer;
                &::after {
                  content: "\f058";
                  font: normal normal normal 1.5em/1 FontAwesome;
                  position: absolute;
                  display: none;
                  top: -2px;
                  color: $gray-400;
                  left: 0px;
                }
              }
            }
          }
        }
      }
      .filter__attributes-more {
        text-align: center;
        margin: 15px 0;
        .more__link {
          font-size: .9em;
          border-radius: 25px;
        }
      }
    }
  }
}

.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .with-legend {
    margin-bottom: 40px;
  }
  span {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
  }
  .rz-base {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .rz-bar-wrapper {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 32px;
    padding-top: 16px;
    margin-top: -16px;
    box-sizing: border-box;
    rz-transparent .rz-bar {
      background: transparent;
    }
    .rz-left-out-selection .rz-bar {
      background: #df002d;
    }
    .rz-right-out-selection .rz-bar {
      background: #03a688;
    }
  }
  .rz-draggable {
    cursor: move;
  }
  .rz-bar {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: #d8e0f3;
    border-radius: 2px;
  }
  .rz-selection {
    z-index: 2;
    background: #0db9f0;
    border-radius: 2px;
  }
  .rz-pointer {
    top: -14px;
    z-index: 3;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: #0db9f0;
    border-radius: 16px;
    .rz-active {
      z-index: 4;
    }
    .rz-active:after {
      background-color: #451aff;
    }
  }
  .rz-pointer:after {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 4px;
    content: '';
  }
  .rz-pointer:hover:after {
    background-color: #fff;
  }
  .rz-bubble {
    bottom: 16px;
    padding: 1px 3px;
    color: #55637d;
    cursor: default;
    .rz-limit {
      color: #55637d;
    }
  }
  .rz-ticks {
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
  }
  .rz-ticks-values-under .rz-tick-value {
    top: auto;
    bottom: -32px;
  }
  .rz-tick {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    margin-left: 11px;
    text-align: center;
    cursor: pointer;
    background: #d8e0f3;
    border-radius: 50%;
    .rz-selected {
      background: #0db9f0;
    }
  }
  .rz-tick-value {
    position: absolute;
    top: -30px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .rz-tick-legend {
    position: absolute;
    top: 24px;
    max-width: 50px;
    white-space: normal;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .rz-vertical {
    position: relative;
    width: 4px;
    height: 100%;
    padding: 0;
    margin: 0 20px;
    vertical-align: baseline;
    .rz-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    .rz-bar-wrapper {
      top: auto;
      left: 0;
      width: 32px;
      height: 100%;
      padding: 0 0 0 16px;
      margin: 0 0 0 -16px;
    }
    .rz-bar {
      bottom: 0;
      left: auto;
      width: 4px;
      height: 100%;
    }
    .rz-pointer {
      top: auto;
      bottom: 0;
      left: -14px !important;
    }
    .rz-bubble {
      bottom: 0;
      left: 16px !important;
      margin-left: 3px;
    }
    .rz-ticks {
      top: 0;
      left: -3px;
      z-index: 1;
      width: 0;
      height: 100%;
    }
    .rz-tick {
      margin-top: 11px;
      margin-left: auto;
      vertical-align: middle;
    }
    .rz-tick-value {
      top: auto;
      left: 24px;
      -webkit-transform: translate(0, -28%);
      transform: translate(0, -28%);
    }
    .rz-tick-legend {
      top: auto;
      right: 24px;
      max-width: none;
      white-space: nowrap;
      -webkit-transform: translate(0, -28%);
      transform: translate(0, -28%);
    }
    .rz-ticks-values-under .rz-tick-value {
      right: 24px;
      bottom: auto;
      left: auto;
    }
  }

}

.rzslider[disabled] {
  cursor: not-allowed;
  .rz-pointer {
    cursor: not-allowed;
    background-color: #d8e0f3;
  }
  .rz-draggable {
    cursor: not-allowed;
  }
  .rz-selection {
    background: #8b91a2;
  }
  .rz-tick {
    cursor: not-allowed;
  }
  .rz-tick.rz-selected {
    background: #8b91a2;
  }
}

#price-range-details {
  display: flex;
  .input-group {
    .input-group-addon {
      min-width: 16px;
      padding: 4px;
      height: 32px;
      padding: 3px 6px;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px 0 0 3px;
    }
  }
  input {
    width: 90px;
    text-align: center;
    display: inline-block;
    height: 32px;
    border: 1px solid #d3d3d3;
    line-height: 20px;
    padding: 2px 4px;
    font-size: 13px;
    color: #999;
    .separator {
      margin-right: 10px;
    }
  }
}
