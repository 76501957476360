.toolbox__view {
  margin-bottom: 1.5em;
  .toolbar {
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }

    .toolbox__view-count {

    }
    .toolbox__pagination {
      display: flex;
      .pagination {
        @include margin-padding(all, 0, all, 0);
        @include e(item) {
          &.active {
            .pagination__link {
              color: $white;
              background-color: $color-base;
              border-color: $color-base;
            }
          }
        }
        @include e(link) {
          color: $color-base;
        }
      }
    }
    .toolbox__sort {

    }
    .toolbox__search {
      .search__input {
        padding: .5em 1em;
        border: 1px solid #e4e2e2;
        border-radius: 3px;
      }
    }
    .toolbox__views {
      .views__list {
        margin: 0;
        .btn__view {
          padding: 0 .2em;
          .icon__view {
            font-size: 1.6em;
            color: #ccc;
          }
          &.activated {
            .icon__view {
              color: $alternative;
            }
          }
        }
      }
    }
    .toolbox__filter {

    }

    //VERSION DE ( APLICADA A PALLOMARO )

    &.v2 {
      .btn {
        border-radius: 0;
        font-size: 1.1em;
        background: #f5f5f5;
        color: #7c7c7c;
        padding: .7em 1em;
      }
      .label-sort {
        font-size: 1.1em;
        font-weight: 600;
      }
      .sort__arrow {
        font-size: 1.3em;
        letter-spacing: -2px;
        position: relative;
        left: 4px;
        top: 8px;
        cursor: pointer;
        span {
          color: #b7b7b7;
          &:hover, &.active {
            color: $alternative;
          }
        }
      }
      .toolbox__pagination {
        .pagination {

          @include e(item) {
            &.active {
              .pagination__link {
                color: $white;
                background-color: $alternative;
                border-color: $alternative;
              }
            }
          }
          @include e(link) {
            color: $alternative;
          }

          .pagination-page {
            &.active {
              a {
                color: $white;
                background-color: $variant;
                border-color: $variant;
              }
            }
            a {
              color: $gray-600;
            }
          }
        }
      }
    }
    &.v3 {
      .btn {
        border-radius: 0;
        font-size: 1.1em;
        background: #f5f5f5;
        color: #7c7c7c;
        padding: .7em 1em;
        border-color: #ccc;
        &.select-btn {
          background: white;

        }
      }
      .label-sort {
        font-size: 1.1em;
        font-weight: 600;
      }
      .sort__arrow {
        font-size: 1.3em;
        letter-spacing: -2px;
        position: relative;
        left: 4px;
        top: 8px;
        cursor: pointer;
        span {
          color: #b7b7b7;
          &:hover, &.active {
            color: $alternative;
          }
        }
      }
      .toolbox__pagination {
        .pagination {

          @include e(item) {
            &.active {
              .pagination__link {
                color: $white;
                background-color: $alternative;
                border-color: $alternative;
              }
            }
          }
          @include e(link) {
            color: $alternative;
          }

          .pagination-page {
            &.active {
              a {
                color: $white;
                background-color: $variant;
                border-color: $variant;
              }
            }
            a {
              color: $gray-600;
            }
          }
        }
      }
    }

  }
}
