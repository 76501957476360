.only_print {
  display: none;
}
@media print {
  .main-content {
    border-bottom: 0px;
  }
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .order-detail {
    width: 100% ;
    padding: 0 20px;
  }
  .total-detail-table {
    padding-left: 0px !important;
  }

  @page {
    margin: 5mm;
    size: A3;
  }
  #footer,
  #header,
  #pestana_der,
  footer-core,
  header-core,
  #lhc_status_container,
  .detalles-pedido-titulo,
  .calificacion,
  .botonera-acciones,
  #scroll-top.fixed,
  .separador-detalles-pedidos,
  .breadcrumb,
  .footer_newsletter {
    display: none;
  }
  section#content div + .container,
  .container.fondo-blanco,
  #breadcrumb-container + div > .container {
    padding: 0 20px;
    width: 100%;
  }
  .logotipo-header-cotizacion{
    img{
      display: block!important;
    }

  }
  .header-cotizacion {
    margin-top: 20px;
    padding: 0;
  }
  a:link:after,
  a:visited:after {
    content: " [" attr(href) "] ";
    font-size: 0%;
  }
  .f_anexos {
    margin-left: 20px;
  }
  .select_print,
  input {
    border: none;
  }
  .header-cotizacion {
    padding: 0 20px;
  }
  .header-cotizacion b {
    font-size: 14px;
  }
  #cotizacion-form .col-md-4,
  .pedidos-bloques1.col-md-4 {
    width: 33.3%;
    float: left;
    padding: 0;
  }
  .detalles-pedidos .table .table-title {
    font: 400 13px/22px "Open sans", sans-serif;
    padding: 5px 7px;
  }
  h4.titulo-detalle-tabla {
    font-size: 15px;
  }
  .table-striped.tb-cotizacion {
    float: right;
  }
  .table.tb-cotizacion td {
    vertical-align: middle;
    /*line-height: auto;*/
  }
  #cotizacion-form .col-md-4,
  #cotizacion-form .detalles-pedidos .col-md-12,
  #cotizacion-form .anotaciones-block.col-md-12,
  #cotizacion-form .formas-block.col-md-12 {
    padding: 0;
  }
  .anotaciones-block .col-md-4 {
    width: 38.5% !important;
  }
  .anotaciones-block .col-md-8 {
    width: 60% !important;
  }
  .table-striped > tbody > tr:nth-child(even) > td,
  .table-striped > tbody > tr:nth-child(even) > th {
    background-color: #f7f7f7 !important;
  }
  .header-cotizacion b span {
    color: #f00 !important;
  }
  .cabezas-info.text-left {
    background-color: #555!important;
    color: #fff !important;
  }
  .cabezas-info.text-left.asesor,
  .pedidos-bloques1 .cabezas-info.text-left.asesor {
    background-color: #555 !important;
  }
  th.table-title {
    background-color: #555 !important;
    color: #fff !important;
    border-color: #fff !important;
  }
  .anotaciones strong {
    background-color: #555 !important;
    color: #fff !important;
  }
  .anotaciones strong.print-green,
  .cabezas-info.text-left.asesor.verdeprint {
    background-color: #555 !important;
  }
  .calificacion {
    display: none;
  }
  .only_print {
    display: inline-block;
  }
  .no_print {
    display: none !important;
  }
  .header-cotizacion strong {
    font-size: 20px !important;
  }
  .header-cotizacion strong span {
    color: #f00 !important;
  }
  .firma strong {
    font-size: 15px !important;
  }
}
