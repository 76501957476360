.destacados-mini {
  .owl-item {
    width: 255px !important;
  }
  .owl-wrapper {
    width: 2550px !important;
  }
}

.grid-products {
  margin: 1.5em 0 2em;
  @include e(heading) {
    .title__text {
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  }
  @include e(items) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .item-product {
      background: white;
      position: relative;
      padding: 5px;
      .badges {
        font-size: .9em;
        color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 1;
        top: 15px;
        left: 15px;
        right: auto;
        .badge {
          border-radius: 0px;
          margin-bottom: 2px;
          &--discount {
            background-color: red;
          }
          &--new {
            background-color: $alternative;
          }
          &--offert {
            background-color: $color-base;
          }
        }
      }
      .badge--feature {
        position: absolute;
        z-index: 9;
        top: 5px;
        right: 5px;
        border-radius: 0px;
        background: none;
        padding: 8px 6px;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-top: solid 49px $variant;
            border-left: solid 40px transparent;
        }
        i {
            position: relative;
            z-index: 9;
            margin-left: 14px;
        }
      }
      .item__image {
        position: relative;
        height: 280px;
        padding: 10px;
        border: solid 1px $gray-200;
        margin-bottom: 1em;
        .image__container {
          margin: 0;
          max-height: 260px;
          min-height: 260px;
          display: flex;
          justify-content: center;
          align-items: center;
          .item-image {
            visibility: visible;
            opacity: 1;
            display: inline-block;
            max-height: 260px;
          }
          .item-image-hover {
            opacity: 0;
            visibility: hidden;
            position: relative;
            display: none;
          }
        }
      }
      .item__name {
        display: flex;
        align-items: center;
        min-height: 1em;
        .item__name_title {
          font-family: $font-family--title;
          color: $text-color;
          margin: 0;
          min-height: 2.5em;
          display: flex;
          align-items: center;
          &:hover {
            color: $color-base;
          }
        }
      }
      .item-exclusive {
        .badge {
          color: white;
          font-style: italic;
          @include m(exclusive) {
            background: $color-base;
          }
          i {
            transform:rotate(90deg);
          }
        }
      }
      .reference {
        margin-bottom: .4em;
        display: flex;
        flex-direction: column;
        .item__reference {
          margin: 0;
          margin-bottom: 5px;
        }
      }
      .wishlist--like {
        .like-icon {
          font-size: 1em;
          &.producto-favorito {
            color: $alternative;
          }
        }
      }
      .item__price {
        padding-top: 0;
        .item__price-list {
          display: flex;
          align-items: center;
          .list-inline-item {
            display: flex;
            align-items: center;
            span {
              &.name-price {
                font-weight: 500;
              }
              &.price {
                color: $color-base;
              }
              &.price-old {
                color: $gray-400;
                text-decoration: line-through;
              }
            }
          }
        }
      }
      .item__price {
        .item__price-list {
          .price-regular {
            span {
              &.price {
                font-size: .9em;
                font-weight: 400;
                color: #aeaeae;
                text-decoration: line-through;
              }
            }
          }
        }
      }
      &:hover {
        .image__container {
          .item-image {
            visibility: hidden;
            opacity: 0;
            display: none;
          }
          .item-image-hover {
            visibility: visible;
            opacity: 1;
            display: inline-block;
            max-height: 260px;
          }
        }
        .item__overlay {
          opacity: 1;
          .item__list-actions {
            top: 0;
          }
        }
      }
      .item__actions--compare {
        color: #bdbdbd;
        &.select--product {
          color: $alternative;
        }
      }
    }
    div {
      &[class*="owl-carousel"] {
        border: none;
        border-top: 0;
        border-bottom: 0;
        [class*="owl-wrapper"] {
          display: flex !important;
          [class*="owl-item"] {
            padding: 10px;
          }
        }
      }
      &[class*="col-"] {
        box-shadow: none;
        padding: 0px 15px;
        transition: width 0.5s, height 4s;
      }
      &.vista-1 {
        &[class*="col-"] {
          box-shadow: none;
          padding: 0px;
          transition: width 1s, height 4s;
        }
      }
      &.vista-2 {
        &[class*="col-"] {
          box-shadow: none;
          padding: 10px;
          transition: width 1s, height 4s;
        }
      }
      &.vista-3 {
        &[class*="col-"] {
          box-shadow: none;
          padding: 10px;
          transition: width 0.5s, height 4s;
        }
      }
    }
    //Vista 1 del Listado del Productos
    .vista-1 {
      border-bottom: 1px solid $gray-200;
      &:last-child {
        border-bottom: none;
      }
      .item-product {
        text-align: left;
        padding: 20px 0px;
        height: inherit !important;
        overflow: hidden;
        &:hover {
          .image__container {
            .item-image {
              display: block !important;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .badges {
          top: 5px;
          left: 5px;
        }
        .item__image {
          float: left;
          width: 22%;
          margin-right: 2%;
          height: auto;
          position: initial;
          border: solid 1px $gray-200;
          margin-bottom: 0;
          .price-badge {
            display: none;
          }
          .image__container {
            min-height: auto;
            .item-image {
              height: 145px;
            }
          }
        }
        .item__info {
          width: 75%;
          float: left;
          .item__name {
            justify-content: flex-start;
            order: 0;
            margin: 0;
            min-height: inherit;
            .item__name_title {
              font-family: $font-family--title;
              color: $text-color;
              &:hover {
                color: $color-base;
              }
            }
          }
          .reference {
            flex-grow: 2;
            margin: 0;
          }
          .item__price {
            padding-top: 0;
            .item__price-list {
              margin: 0;
            }
          }
          .item__list-actions {
            bottom: 5px;
            top: auto;
            margin: 0;
            flex-direction: row;
            width: 100%;
            li {
              display: inline-flex;
            }
          }
        }
        .producto__destacado {
          right: -7px;
          top: -9px;
        }
      }
    }
    //Vista 2 del Listado del Productos
    .vista-2 {
      .item-product {
        text-align: center;
        height: inherit !important;
        .badges {
          top: 5px;
          right: auto;
        }
        .item__image {
          height:380px;
          .image__container {
            max-height: 360px;
            min-height: auto;
            .item-image {
              height: 360px;
              max-height: 360px;
            }
          }
        }
        &:hover {
          .image__container {
            .item-image {
              display: block !important;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .item__info {
          .item__name {
            justify-content: center;
            .item__name_title {
              justify-content: center;
            }
          }
          .item__price {
            .item__price-list {
              justify-content: center;
            }
          }
          .item__list-actions {
            flex-direction: row;
            width: 100%;
            li {
              display: inline-flex;
            }
          }
        }
      }
    }
    //Vista 3 del Listado del Productos
    .vista-3 {
      .item-product {
        text-align: left;
        height: inherit !important;
        .badges {
          top: 5px;
        }
        &:hover {
          .image__container {
            .item-image {
              display: block !important;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .item__info {
          .item__list-actions {
            flex-direction: row;
            li {
              display: inline-flex;
            }
          }
        }
        .item-exclusive {
          .badge {
            color: $gray-600;
            font-style: italic;
            @include m(exclusive) {
              background: none;
            }
          }
          i {
            transform:rotate(90deg);
          }
        }
      }
    }
  }
  .loader-general {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
    color: $gray-500;
    width: 100%;
  }
}
