// ** ---------- MENU HEADER ---------- ** //
.menu__header {
  ul, ul li, ul li a {
    @include margin-padding(all, 0, all, 0);
    display: block;
    z-index: 1;
  }
  ul {
    li {
      float: left;
      z-index: 999;
      &:hover {
        a {
          color: white;
          background: $variant;
        }
      }
      a {
        padding: 8px;
        font-size: 8.8pt;
        font-weight: 700;
        text-decoration: none;
        color: $text-color;
        cursor: pointer;
        &:hover {
          background: lighten($variant, 10%);
        }
        i {
          font-size: 1.2em;
          margin-right: 0.3em;
          display: none;
        }
      }
      &.dropdown {
        > a {
          padding-right: 25px;
          &:after {
            content: "\f078";
            font-family: FontAwesome;
            padding-right: 0.5em;
            position: absolute;
            top: 9px;
            right: 0px;
          }
        }
      }
      &:first-child {
        border-left: none;
      }
      &:hover {
        > ul {
          left: auto;
          > li {
            height: auto;
            position: relative;
          }
        }
      }
    }
    ul {
      position: absolute;
      left: -9999px;
      border-left: 1px solid lighten($variant, 5%);
      li {
        height: 0;
        border-left: none;
        transition: all .25s ease;
        a {
          padding: 12px;
          width: 255px;
          color: white;
          font-weight: 500;
          line-height: 1.3;
          cursor: pointer;
          background: $variant;
          border-top: 1px solid lighten($variant, 5%);
        }
        i {
          position: absolute;
          color: white;
          top: 13px;
          right: 8px;
          z-index: 99;
        }
        &:hover > a,
        a:hover {
          color: white;
        }
      }
      ul {
        margin-left: 100%;
        top: 0;
        max-height: 500px;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        &::-webkit-scrollbar-track{
          background-color: $variant;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba($color: $color-base, $alpha: 0.5);
        }
        li {
          background: $variant;
          a {
            p {
              margin: 0;
              padding: 0;
              color: white;
              font-weight: 500;
              line-height: 1.3;
              cursor: pointer;
              font-size: 10pt;
            }
            img {
              display: none;
            }
          }
        }
      }
    }
  }
  .navbar-header {
    .navbar-toggle {
      background: $color-base;
    }
  }
  .multilevel-menu {
    li {
      a {
        .subitem-menu {
          img {
            display: none;
          }
          p {
            font-size: 10pt;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// ** ---------- ALERTS HEADER ---------- ** //
.alert-header {
  display: flex;
  align-items: center;
  position: relative;
}
.alert-close-button {
  position: absolute;
  right: 0;
}
.alert-canasta {
  &.alert {
    padding: 0;
    margin-bottom: 0;
  }
  .alert-header {
    h5 {
      font-size: 1.0em;
    }
  }
}
