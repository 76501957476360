// ==========================================
//   Mixin: BEM Selectors
// ==========================================
@mixin e($element) {
	&__#{$element} {
    @content;
  }
}
@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

// ==========================================
//   Mixin: Margin / Padding
// ==========================================
@mixin margin-padding($m-direction, $m-amount, $p-direction, $p-amount) {
    @if $m-direction == all {
        margin: $m-amount;
    } @else {
        margin-#{$m-direction}: $m-amount;
    }
    @if $p-direction == all {
        padding: $p-amount;
    } @else {
        padding-#{$p-direction}: $p-amount;
    }
}

// ==========================================
//   Mixin: transiciones
// ==========================================
@mixin transition($what: all, $time: 0.1s, $how: ease-in-out) {
    transition: $what $time $how;
}
// ==========================================
//   Mixin: border-radius
// ==========================================
@mixin border-radius($pixel...) {
    border-radius: $pixel;
}
// ==========================================
//   Mixin: Margin y Padding
// ==========================================
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side == 'all' {
      .m#{$space} {
        margin: #{$space}px !important;
      }

      .p#{$space} {
        padding: #{$space}px !important;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}
