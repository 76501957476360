.search__product-advanced{
  .content__search-advanced{
    .title__search-advanced{
      padding: 5px 30px;
      h6{
        font-weight: 700;
      }
    }
    .form__search-advanced{
      padding: 15px 30px;
      .form-group{
        margin-bottom: 8px;
        .description__form {
          font-size: 0.8em;
        }
        .link--form {
          font-size: 0.7em;
          &:hover {
          }
        }
      }
      .form--input {
          .input-select {
          box-shadow: none;
          padding: 5px 10px;
          height: auto;
        }
      }
    }
  }
}
