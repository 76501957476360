.fullwidth {
  background: $white;
  @include m(bg) {
    background: #eaeaea;
  }
}

.bg-base{
  background-color:$color-base !important;
}
.bg-base-dark{
  background-color:$base-darken !important;
}
.bg-base-lighten{
  background-color:$base-lighten !important;
}
.bg-variant{
  background-color: $variant !important;
}
.bg-alternative{
  background-color: $alternative !important;
}
.bg-gradient {
  background: $base-lighten !important;
  background: -webkit-linear-gradient(left, $base-lighten 1%, $color-base 100%) !important;
  background: linear-gradient(to right, $base-lighten 1%, $color-base 100%) !important;
}

.bg-white {
  background: white;
}

.bg-transparent {
  background: transparent;
}

.bg-gray-50 {
  background: $gray-50;
}

.bg-gray-100 {
  background: $gray-100;
}

.bg-gray-200 {
  background: $gray-200;
}

.bg-gray-300 {
  background: $gray-300;
}

.bg-gray-400 {
  background: $gray-400;
}

.bg-gray-500 {
  background: $gray-500;
}

.bg-gray-600 {
  background: $gray-600;
}

.bg-gray-700 {
  background: $gray-700;
}

.bg-gray-800 {
  background: $gray-800;
}

.bg-gray-900 {
  background: $gray-900;
}

body {
  min-width: 966px !important;
}
