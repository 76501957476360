.d-block{
  display: block;
}
.d-inline-block{
  display: inline-block;
}
.d-flex{
  display: flex;
}
.justify-content-start{
  justify-content: flex-start;
}
.justify-content-end{
  justify-content: flex-end;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-around{
  justify-content: space-around;
}
.align-items-start{
  align-items: flex-start;
}
.align-items-end{
  align-items: flex-end;
}
.align-items-center{
  align-items: center
}
.align-items-baseline{
  align-items: baseline;
}
.align-items-stretch{
  align-items: stretch;
}
.flex-nowrap{
  flex-wrap: nowrap
}
.flex-wrap{
  flex-wrap: wrap
}
.flex-wrap-reverse{
  flex-wrap: wrap-reverse
}
