.cart {
  .table__base-cells {
    a {
      color: $text-color;
    }
    .item-name {
      margin-bottom: 5px;
      + ul > li {
        line-height: 1.4;
      }
    }
  }
  .fa-1-3x {
    font-size: 1.3em;
  }
  .btn-toggle {
    width: 100%;
    border-radius: 0;
    background-color: #f9f9f9;
    border-color: #f7f7f7;
    font-weight: 600;
    margin-top: 0;
    display: flex;
    &:hover {
      background-color: #f7f7f7;
      border-color: #f9f9f9;
    }
    .titulo-canasta-sidebar {
      font-weight: 600;
      padding: 10px 0;
      margin: 0;
      &:after {
        content: "\f078";
        font-family: FontAwesome;
        font-size: 13px;
        padding-right: 0.5em;
        position: absolute;
        top: 18px;
        right: 25px;
      }
    }
  }
  .shipping__box-title {
    font-weight: 600;
    margin-top: 5px;
    display: flex;
  }
  .shipping__box {
    background-color: #f9f9f9;
    margin-bottom: 10px;
    .shipping__box-inner {
      .well {
        min-height: 20px;
        padding: 5px;
        margin-bottom: 20px;
        background-color: inherit;
        border: inherit;
        border-radius: inherit;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        ul {
          padding-inline-start: 12px;
          li {
            list-style: none;
          }
        }
      }
    }
  }
  .shipping__box-others {
    background-color: #f9f9f9;
    margin-bottom: 10px;
    padding: 12px;
    .shipping__icon {
      margin-top: -2px;
      margin-right: 7px;
    }
  }
  .table__base-cells {
    border: 0;
  }
  .price {
    color: $color-base;
  }
  .item__product-add {
    .product-add__quantity {
      display: flex;
      justify-content: center;
      .quantity-btn {
        font-size: .8em;
        background: $variant;
        color: #fff;
        top: 3px;
        width: 1.5em;
        right: 11px;
        line-height: 1.5;
        height: 1.5em;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        &:hover {
          background: rgba($variant,0.7);
        }
        &.quantity--up {
          left: inherit;
        }
        &.quantity--down {
          top: 20px;
        }
      }
      .quantity-input {
        background: $gray-200;
        border: none;
        width: 110px;
        font-size: 1em;
        height: 38px;
        text-align: center;
        padding-right: 5px;
        border-radius: 8px;
      }
    }
  }
  .comment-add {
    border-bottom: 1px solid #333;
    .list-group-item {
      border: 0;
      background-color: transparent;
      button {
        color: #777;
      }
    }
  }
}
.mark-img {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 5px;
}
