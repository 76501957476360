.main__footer {
  color: white;
  @include margin-padding(top, 0em, all, 2em 0 0);
  .widget {
    margin-bottom: 1em;
    @include e(title) {
      text-transform: uppercase;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
      @include margin-padding(all, 10px 0 20px, all, 10px 0);
    }
    @include e(list) {
      &-item {
        position: relative;
        @include margin-padding(bottom, 0.4em, left, 1.5em);
        &:before {
          content: "\f054";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          font-size: 0.9375em;
          padding-right: 0.5em;
          position: absolute;
          left: 0;
          top: 0;
        }
        &.bordered {
          border-bottom: 1px dashed rgba(145, 145, 145, 0.3);
          @include margin-padding(bottom, 0.3em, bottom, 0.3em);
        }
        a {
          color: inherit;
          display: block;
          position: relative;
          @include transition();
          &:hover {
            left: .5em;
          }
        }
        &.no-icon {
          padding-left: 0;
          &:before {
            content: none;
          }
        }
      }
    }
    @include e(brand) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 230px;
      .company__text {
        text-align: center;
        .company__title {
          font-weight: 600;
          padding: 0 2em;
          line-height: 1.3;
        }
      }
    }
  }
  .widget__social {
    display: flex;
    margin-top: 1em;
    .social__item {
      margin-right: 0.2em;
      a {
        display: flex;
        width: 40px;
        height: 40px;
        color: #fff;
        border: 1px solid;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        opacity: .7;
        &:hover {
          opacity: 1;
          background: rgba($white, 0.1);
        }
      }
    }
  }
  .opcions__cards {
    padding: 1em;
    background: $white;
    color: #333;

    .payment__opcions,
    .apps__opcions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .payment__opcions {
      justify-content: flex-start;
    }
  }
  .derechos {
    text-align: center;
    padding: 1em;
    .copy {
      margin-bottom: .1em;
      .link {
        color: $gray-400;
        text-decoration: underline;
      }
    }
  }
  .label-title {
    font-weight: 400;
    margin: 0;
  }
  .panel {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .panel-heading {
    padding: 0;
    border: 0;
  }
  .panel-body {
    border-top: 0 !important;
    padding: 0;
  }
  &--logo {
    border-top: 1px solid $gray-400;
    padding-top: 2em;
  }
}

// Anuncio Footer
.features__store {
  padding: 0;
  background: white;
  border-bottom: 5px solid $color-base;
  .row-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

// SCROLL TOP BTN
.scroll-top {
  position: fixed;
  bottom: 120px;
  right: 25px;
  display: inline-block;
  visibility: hidden;
  cursor: pointer;
  z-index: 999;
  border-radius: 0;
  opacity: 0;
  color: white;
  background: $color-base;
  font-size: 2em;
  line-height: 1;
  padding: .3em .5em;
  text-align: center;
  @include transition();
  &:hover {
    color: white;
    background: darken($color-base, 10%);
  }
  &.fixed {
    visibility: visible;
    opacity: 1;
  }
}
.info-cookie {
  padding: 15px 0px;
  background: rgba($color-base, .9);
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  z-index: 999;
  a{
    color: white;
    font-weight: 700;
    text-decoration: underline;
    &:hover{
      color: rgba(white,0.6);
    }
  }
}
.marca-esolutions {
  margin-bottom: .1em;
  text-align: right;
}

.terminos-y-condiciones {
  height: 380px;
  width: 70%;
}
.advertisement__footer {
  border-top: 1px solid $gray-400;
}
