.search-page {
  .listado-carga-rapida {
    table {
      tbody {
        tr {
          td {
            vertical-align: middle;
            a {
              text-decoration: none;
            }
            .categoria-principal {
              color: $color-base;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .add-cgrapida {
    padding: 7px 10px;
    margin: 6px 4px;
    position: relative;
    top: 2px;
    border: 0;
  }
  .icon-button {
    display: inline-block;
    width: 35px;
    height: 35px;
    text-indent: -9999px;
    background: #6dbcdb;
    border: 1px solid #ddd;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.45);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.45);
    font-size: 16px;
    border-radius: 2px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  .producto-favorito {
    color: white;
    background: $red;
    border-bottom: $red;
  }
  .separator.text-buscar {
    display: none;
  }
  .btn-fast-search {
    padding: 2px 11px;
  }
  .boton-busqueda-productos {
    height: 27px;
    padding: 5px 7px;
    color: #a4a4a4;
    font-size: 13px;
    margin-right: 10px;
    line-height: 20px;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-radius: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 130px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .pagination-control.pagination-prev a, .pagination-control.pagination-next a {
    display: block;
    text-align: center;
    padding-top: 4px;
  }
  .pagination-items {
    float: left;
    width: auto;
    min-width: 35px;
    overflow: hidden;
    max-width: 216px;
  }
  .toolbox-filter .sort-box div {
    margin-bottom: 4px;
  }
  .filter-resume {
    font-size: 0.8em;
    position: relative;
    top: 5px;
    display: block;
    line-height: 30px;
    color: #a4a4a4;
    margin-left: 0 !important;
    float: right;
  }
}
