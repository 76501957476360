.header__logo {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: $screen-sm-max) {
        margin: 0 0 1em;
    }

    img {
        width: auto;
        max-height: 95px;
    }

    &-text {
        @include margin-padding(all, 7px 0 0 0, all, 0); 
        .text-hidden {
            font: 0/0 a;
            text-shadow: none;
            color: transparent;
        }
    }
}