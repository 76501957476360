.my-account {
  p {
    text-align: justify;
  }
  .contenido-actualizar-registro {
    .anuncio-cuenta {
      display: flex;
      align-content: center;
      align-self: flex-end;
    }
    #actualizacion-usuario {
      .nav-pills {
        li {
          margin-bottom: 0;
        }

        li.active > a {
          color: #fff;
          border-radius: 4px 4px 0 0;
          background: $color-base;
          border-left: 1px solid #f5f5f5;
          border-right: 1px solid #f5f5f5;
          border-top: 1px solid #f5f5f5;
        }
        li > a {
          border-left: 1px solid #f5f5f5;
          border-radius: 4px 4px 0 0;
          border-right: 1px solid #f5f5f5;
          border-top: 1px solid #f5f5f5;
          color: #555;
        }

      }
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  fieldset {
    margin-bottom: 10px;
    border: 0;
  }
  .actualizar-registro {
    border: 1px solid #ccc;
    padding: 25px 20px 0 !important;
    .tab-pane{
      #account-form{
        .form-group{
          .img-register{
            border-radius: 50%;
          }
        }
      }
    }

  }
  .tab-content {
    margin-bottom: 10px;
    overflow: hidden;
    .form-control {
      box-shadow: none;
      border-radius: 3px;
      height: 38px;
      font-size: 13px;
      color: #555;
      .input-sm {
        padding: 5px 10px;
        line-height: 1.5;
      }
    }
  }
}
