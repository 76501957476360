.border {
   border: 1px solid #dee2e6;
}

.border-top {
   border-top: 1px solid #dee2e6;
}

.border-right {
   border-right: 1px solid #dee2e6;
}

.border-bottom {
   border-bottom: 1px solid #dee2e6;
}

.border-left {
   border-left: 1px solid #dee2e6;
}

.border-0 {
   border: 0;
}

.border-top-0 {
   border-top: 0;
}

.border-right-0 {
   border-right: 0;
}

.border-bottom-0 {
   border-bottom: 0;
}

.border-left-0 {
   border-left: 0;
}

.border-primary {
   border-color: #007bff;
}

.border-secondary {
   border-color: #6c757d;
}

.border-success {
   border-color: #28a745;
}

.border-info {
   border-color: #17a2b8;
}

.border-warning {
   border-color: #ffc107;
}

.border-danger {
   border-color: #dc3545;
}

.border-light {
   border-color: #f8f9fa;
}

.border-dark {
   border-color: #343a40;
}

.border-white {
   border-color: #fff;
}

.rounded {
   border-radius: 0.25rem;
}

.rounded-top {
   border-top-left-radius: 0.25rem;
   border-top-right-radius: 0.25rem;
}

.rounded-right {
   border-top-right-radius: 0.25rem;
   border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
   border-bottom-right-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem;
}

.rounded-left {
   border-top-left-radius: 0.25rem;
   border-bottom-left-radius: 0.25rem;
}

.rounded-circle {
   border-radius: 50%;
}

.rounded-0 {
   border-radius: 0;
}
.clear {
  clear: both;
  height: 2px;
}
.w100{
  width: 100%;
}
.img-margin{
  margin: 0 auto;
}
