.newsletter {
  @include margin-padding(all, 2.5em 0, all, 2.5em 0);
  .newsletter__form {
    .form-container {
      width: 100%;
      @include margin-padding(all, 1.125em 0 1.125em, all, 0);
      position: relative;
      .newsletter__input {
        height: 50px;
        border: 0;
        &[type='email'] {
          background: transparent;
        }
        &[type='text'] {
          background: $white;
          font-size: .9em;
          opacity: .9;
        }
        &[type='submit'] {
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }
    }
    .newsletter__title {
      text-transform: uppercase;
    }
  }
  &.newsletter__footer {
    @include margin-padding(all, 0, all, 0);
    .newsletter__form {
      .form-container {
        .newsletter__input {
          margin-top: 8px;
          height: 33px;
          padding: 0 9px;
          font-weight: normal;
          width: 42.7%;
          color: $text-color;
          &[type='submit'] {
            background: $variant;
            color: $color_base;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            border: none;
            padding: 0 13px;
            width: initial;
          }
        }
      }
    }
  }
}