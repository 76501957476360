/*HEADER*/
.header__zone3 {
  &.v1 {
    .icon-bar-b2b{
      display: inherit;
      height: inherit;
      top: 12px;
      .icons-header{
        display: inherit;
      }
    }
  }
}
/*CONTENIDO*/
.banner-landing{
  &.v1{
    margin: 1em 0 auto;
    div {
      margin: auto;
    }
  }
}
