// ==========================================================================
// ACCORDION BASE
// ==========================================================================
.accordion__smart {
  .accordion-link {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .6em;
    font-size: 1.2em;
    font-weight: 700;
    background: #ececec;
    color: #434343;
    margin-top: .5em;
    &:hover,
    &:hover::after {
      cursor: pointer;
      color: $color-base;
    }
    &.active {
      color: #fff;
      background: $color-base;
      &::after {
        font-family: FontAwesome;
        content: "\f107";
        color: #fff;
      }
    }
    &::after {
      font-family: FontAwesome;
      content: "\f105";
      position: absolute;
      line-height: 1.2;
      float: right;
      right: .3em;
      top: 0;
      font-size: 1.7em;
      color: #7288a2;
      padding: 5px;
      text-align: center;
    }
  }
  .content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    clear: both;
    transition: all 0.2s ease 0.15s;
    p {
      font-size: 1em;
      font-weight: 300;
    }
    &.active {
      opacity: 1;
      padding: .5em 0;
      max-height: 100%;
      transition: all 0.35s ease 0.15s;
    }
  }
}
.submenu-vertical{
  border-left: 8px solid #e1e1e1;
  margin-left: 2px;
}
