.order-detail {
  font: normal 0.9em/1.4 $font-family--primary;
  .separador-detalles-pedidos {
    margin: 10px;
  }
  .titulo-detalle-tabla {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 15px;
  }
  .logotipo-header-cotizacion {
    img {
      display: hide;
      width: 200px;
      margin-left: 21%;
    }
  }
  .header-cotizacion {
    padding: 0 15px;
    strong {
      font: 700 1.6em/1.4 $font-family--primary;
      text-transform: capitalize;
    }
    .datos-header {
      font: normal 1em/1.4 $font-family--primary;
      strong {
        font: 700 1.6em/1.4 $font-family--primary;
        text-transform: capitalize;
        span {
          font: 700 0.9em/1.4 $font-family--primary;
          color: #f00;
        }
      }
    }
    .cotizacion-datos-header {
      font: normal 0.9em/1.4 $font-family--primary;
      strong {
        font: 700 1.6em/1.4 $font-family--primary;
        text-transform: capitalize;
        span {
          font: 700 0.9em/1.4 $font-family--primary;
          color: #f00;
        }
      }
    }
  }
  .detalles-pedidos h2 {
    font-size: 12px;
    margin-bottom: 0px;
    color: #565656;
    font-family: $font-family--primary;
    line-height: 1.3;
    margin-top: 0;
  }
  .cabezas-info {
    background: $color-base;
    padding: 5px 20px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    border: 1px solid #e0e0e0;
  }
  .entregas-contactos {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    width: 100%;
    display: inline-block;
    padding: 0 11px;
    ul {
      padding: 10px;
      li {
        margin: 0;
        border-bottom: 1px dashed #ddd;
        .datos-entregas {
          width: 100%;
          font-size: 13px;
        }
      }
    }
  }
  .only_print {
    display: none;
  }
  .md-margin, .botonera-acciones {
    margin-bottom: 15px;
    margin-left: -15px;
  }
  .anotaciones {
    border: 1px solid #e0e0e0;
    margin: 0 0 15px 0;
    padding: 0 0 5px;
    text-align: center;
    .cli-ven {
      text-align: left;
      span {
        padding: 8px;
      }
    }
    strong {
      display: block;
      padding: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #ddd;
      font-weight: 600;
      color: #fff !important;
      text-align: center;
    }
  }
}

table.table.cart-table-cotizacion.tb-cotizacion > tbody > tr > td {
  vertical-align: middle;
}

.cart-table-cotizacion.tb-cotizacion tr th.table-title {
  text-align: center;
}
