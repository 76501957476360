.widget__aside {
  margin-bottom: 1.5em;
  .widget__title {
    @include margin-padding(all, 0em, all, 1em);
    position: relative;
    display: flex;
    justify-content: space-between;
    color: white;
    &.has-icon {
      display: flex;
      align-items: center;
    }
    &-icon {
      padding: .2em .8em;
      font-size: .9em;
    }
  }
  .widget__wrapper {
    padding: .6em 1em;
  }
  .form__check {
    display: flex;
    position: relative;
    padding-left: 26px;
    margin: .2em 0;
    user-select: none;
    .widget__check-label {
      color: #969696;
      font-weight: 600;
      font-size: .9em;
      margin-top: -4px;
      .widget__check-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 4px;
        top: 0;
        z-index: 1;
      }
      .widget__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border: 2px solid #d1cfcf;
        border-radius: 50%;
        cursor: pointer;
        &:after {
          content: "\f058";
          font: normal normal normal 1.5em/1 FontAwesome;
          position: absolute;
          display: none;
          top: -2px;
          color: $alternative;
          left: -1px;
        }
      }
      .widget__check-input:checked ~ .widget__checkmark:after {
        display: block;
      }
    }
  }
}
.widget_filtro {
  padding: 1em 0 1em 1em;
  font: 600 0.9em/1 "Poppins", Helvetica, sans-serif;
}

