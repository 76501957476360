// CARRUSEL DE PRODUCTOS DEL HOME
.carousel__grid {
  .carousel-wrapper {
    position: relative;
    overflow: hidden;
    .carousel-controls {
      display: flex;
      justify-content: flex-end;
      .carousel-btn {
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        &:hover {
          background: $variant;
          border-color: $variant;
          &::before {
            color: $white;
          }
        }
      }
    }
  }
  &.v2 {
    .carousel-wrapper {
      .carousel-controls {
        position: absolute;
        top: 18px;
        width: auto;
        padding: 0;
        height: auto;
        .carousel-btn {
          border-radius: 0;
          font-size: .9em;
          color: white;
          background: $variant;
          border: none;
          &:hover {
            background: darken($variant, 10%);
          }
        }
      }
    }
  }
  &.category {
    .carousel-controls {
      &.categories_btns {
        height: 100%;
        padding: 0;
        z-index: 1;
        background: transparent;
        align-items: center;
        width: 50px;
        top: 0;
        &.next {
          right: 0;
        }
        &.prev {
          left: 0;
        }
        .carousel-btn {
          border-radius: 0;
          border: none;
          font-size: 1.3em;
        }
      }
    }
    .category__item-link {
      img {
        height: 285px;
        object-fit: cover;
      }
    }
  }
}
