.title__404 {
   font-size: 10em;
   font-weight: bold;
   color: $color-base;
   &:after {
      content: '';
      width: 100px;
      height: 2px;
      background: $alternative;
      display: block;
      margin: 5px auto 15px;
   }
}
.descriptions__404 {
   padding: 2em;
   border: 1px solid #ddd;
}
