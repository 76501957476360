.login {
  .box__card {
    padding: 2em;
    border-radius: 12px;
  }
  .tips__recover-list {
    p {
      margin-bottom: .3em;
    }
  }

  &.bg--img {
    background-position: right center;
    background-size: cover;
  }
  .origami {
    position: relative;
    font-size: 1.2em;
    font-weight: 600;
    color: #fff;
    &:after {
      content: '';
      position: absolute;
      right: -15px;
      top: 0;
      border-top: 23px solid transparent;
      border-bottom: 0 solid transparent;
    }
    &:before {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      border-top: 23px solid transparent;
      border-bottom: 0 solid transparent;

    }
  }
}
