.header__search {
  @include margin-padding(top, .7em, all, 0);
  position: relative;
  border: 2px solid $gray-200;
  border-radius: 25px;
  width: 100%;
  padding: inherit;
  margin: inherit;
  form {
    width: 100%;

  }
  .header__form {
    display: flex;
    border: none;
    border-radius: 25px;
    z-index: 2;
    @include e(input) {
      padding: 1em 1.25em;
      border: none;
      flex-grow: 1;
      border-radius: 25px;
      width: 0;
      &.expanded {
        width: 100%;
        background: $gray-100;
        right: 0;
      }
    }
    @include e(btn) {
      background: transparent;
      font-size: .9em;
      color: initial;
      position: absolute;
      right: 0;
      top: 5px;
      .icon-magnifier{
        color: $gray-800;
      }
      .closed {
        display: none;
        &.visible {
          display: inline-block;
          opacity: .2;
        }
      }
    }
  }
}
.dropdown-menu {
  margin: 0px;
  li{
    a{
      color: $text-color;
      &:hover{
        background: $color-base;
      }
      &:active {
        background: $color-base;
      }
      &:focus {
        background: $color-base;
      }
    }
  }
  .active{
    a{
      background: $color-base;
    }
  }
}
