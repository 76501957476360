.contact__list {
  .contact__item {
    border-bottom: 1px dotted #aeaeae;
    padding: .3em 0;
  }
}

.box__sedes-image {
  min-height: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 0 5px #e8e8e8, inset 0 0 10px #e8e8e8;
}
.box__sedes-title{
  .link-sedes{
    color: $color-base;
    &:hover{
      color: $base-darken;
    }
  }
}
.contact__img {
  img {
    cursor: pointer;
  }
  .openmodal {
    &:before {
      font: normal normal normal 1px/1 FontAwesome;
      content: "\f0b2";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      color: rgba(0, 0, 0, 0.12);
      justify-content: center;
      align-items: center;
      font-weight: 100;
      opacity: 0;
    }
    &:hover {
      cursor: pointer;
      &before {
        font-size: 2em;
        opacity: 1;
      }
    }
  }
}

.tabs__contacts {
  .panel-heading {
    color: #808080;
    border-radius: 0;
    padding: 0;
    background: transparent;
    border-bottom: 2px solid $color-base;
    .panel-title {
      font: 600 .9em/1 $font-family--primary;
      .accordion-toggle {
        display: block;
        padding: 1em;
      }
    }
  }
  .panel-group {
    margin: 0;
  }
  .panel-default {
    border: none;
    margin: 0;
    box-shadow: none;
    &.panel-open {
      background: #fbfbfb;
      .accordion-toggle {
        background: $color-base;
        color: white;
      }
    }
  }
  .panel-body {
    display: flex;
    align-items: start;
  }
}

.contact__detail {
  #myImg {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  #myImg:hover {
    opacity: 0.7;
  }

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    width: 90%;
    max-width: 800px;
  }

  /* Caption of Modal Image */
  #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }

  /* Add Animation */
  .modal-content, #caption {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0)
    }
    to {
      -webkit-transform: scale(1)
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  /* 100% Image Width on Smaller Screens */
  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }
}
