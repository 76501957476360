.orders {
  .barra-mis-cotizaciones {
    .small-box {
      border-radius: 0px;
      .icon {
        position: absolute;
        top: -12px;
        right: 25px;
        z-index: 0;
        font-size: 66px;
        color: rgba(0, 0, 0, 0.15);
        transition: transform 0.2s linear;
      }
    }
  }
  .clear {
    clear: both;
    height: 2px;
  }
  .bg-aqua {
    background-color: #00c0ef;
  }
  .bg-yellow {
    background-color: #f39c12;
  }
  .bg-green {
    background-color: #008000;
  }
  .bg-red {
    background-color: #dd4b39;
  }
  .small-box:hover i {
    transform: scale(0.9);
    color: rgba(241, 241, 241, 0.45);
  }
  .small-box {
    border-radius: 10px;
    overflow: hidden;
    .inner {
      padding: 10px 10px 5px;
    }
    h3 {
      margin: 0;
      white-space: nowrap;
      padding: 0;
      text-shadow: 1px 1px 4px rgba(49, 49, 49, 0.38);
    }
    p {
      font-size: 17px;
      color: #fff;
      margin-bottom: 5px;
    }
    .icon {
      transition: all 0.3s linear;
      position: absolute;
      top: -10px;
      right: 25px;
      z-index: 0;
      font-size: 70px;
      color: rgba(0, 0, 0, 0.15);
      transition: transform 0.2s linear;
    }
  }
  #filtros-top-cotizacion {
    font-size: .9em;
  }
  .filter-resume {
    font-size: 0.8em;
  }
  .search-cotizacion {
    input {
      position: absolute;
    }
  }
  .rangos {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .form-control {
      width: 165px;
    }
  }
  #buscar-filtrar {
    right: 10px;
    color: #b7b7b7;
    position: absolute;
    top: 10px;
  }
  .barra-mis-cotizaciones {
    .tabla-mis-cotizaciones {
      .table {
        margin-bottom: 35px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        tr td {
          padding: 10px 5px;
          vertical-align: middle;
          border-right: none !important;
          a {
            color: $color-base;
            &:hover {
              color: $base-darken;
            }
          }
        }
      }
    }
    .paginacion-cotizacion {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .pagination-sm {
        .pagination-page {
          a {
            padding: 6px 12px;
            font-size: 0.8em;
            color: $gray-600;
            background-color: $white;
            border-color: $gray-300;
          }
        }
      }
    }
  }
}

.tracking {
  .tracking__box--icon-status {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 30px 0;
    .tracking__icon-status {
      display: inline-block;
      width: 100px;
      border-radius: 50%;
      background: $variant;
    }
    .tracking__icon--no-status {
      display: inline-block;
      width: 100px;
      border-radius: 50%;
      background: #666;
    }
    .tracking__icon--line-status {
      display: inline-block;
      height: 5px;
      width: 30px;
      background: $variant;
    }
    .tracking__icon---no--line-status {
      display: inline-block;
      height: 5px;
      width: 30px;
      background: #666;
    }
  }
  .tracking__info {
    .table {
      h3 {
        font-weight: 700;
      }
      h6 {
        font-weight: 700;
      }
      p {
        font-weight: 400;
      }
    }
  }
}

.tracking-detail-text-center {
  text-align: center;
}
