.category__header {
  margin-bottom: 1.5em;
  padding: .5em;
  &-img {
    .header__title-img {
      max-width: 100px;
      min-height: 100px;
      @include m(placeholder) {
        min-height: 100px;
        max-height: 100px;
      }
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    .header__title {
      padding: 5px 0 0 15px;
      &-text {
        @include margin-padding(all, .4em 0 0.3em, all, 0 0 .5em);
      }
    }
  }
}
