// ==========================================================================
// COLORES REDES SOCIALES
// ==========================================================================
$colores-social: (
  facebook: #3b5998,
  googleplus: #dd4b39,
  instagram: #517fa4,
  linkedin: #007bb6,
  pinterest: #cb2027,
  twitter: #00aced,
  youtube: #b00,
  soundcloud: #f60,
);
// ==========================================================================
// COLORES BASE
// ==========================================================================
$base-darken: darken($color-base, 10%);
$base-lighten: lighten($color-base, 10%);

$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-200: #EEEEEE;
$gray-300: #E0E0E0;
$gray-400: #BDBDBD;
$gray-500: #9E9E9E;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$black: #000;
$white: #fff;
$red: #C62828;
$yellow: #fec01a;

.color-base {
  color: $color-base;
}

.base-darken {
  color: $base-darken;
}

.base-lighten {
  color: $base-lighten;
}

.variant {
  color: $variant;
}

.alternative {
  color: $alternative;
}

.complement {
  color: $complement;
}

.black {
  color: $black;
}

.white {
  color: $white;
}

.red {
  color: $red;
}
