// DETALLE DE LA PAGINA PROCEDIMIENTO

.header-procedure {
  &.detail {
    height: 500px;
  }
  &.list {
    height: 200px;
    background-position: center right;
    background-repeat: no-repeat;
    border-bottom: 5px solid $variant;
    display: flex;
    align-items: center;
  }
  .title {
    margin-bottom: .3em;
  }
  .subtitle {
    font-weight: 300;
    font-size: 1.1em;
  }
}
.head {
  align-items: baseline;
  justify-content: space-between;
  .title__head {
    font-size: 2em;
    font-weight: 700;
    color: $color-base;
    margin-top: 0;
  }
}
.shared_options {
  .shared-list {
    margin: 0;
    display: flex;
    align-items: center;
    .shared__item {
      margin: 0 .2em;
      &.email {
        margin-right: 1em;
        a {
          color: #474747;
        }
      }
      .item-link {
        width: 2em;
        height: 2em;
        background: #b0b0b0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
        &.facebook{
          background-color : #3b5998;
        }
        &.googleplus{
          background-color : #dd4b39;
        }
        &.instagram{
          background-color : #517fa4;
        }
        &.linkedin{
          background-color : #007bb6;
        }
        &.pinterest{
          background-color : #cb2027;
        }
        &.twitter{
          background-color : #00aced;
        }
        &.youtube{
          background-color : #b00;
        }
        &.soundcloud{
          background-color : #f60;
        }
      }
    }
  }
}
.main-procedure {
  .widget__aside {
    margin-top: 0;
  }
}
