.table__compare {
   > thead > tr:first-child > td,
   > tbody > tr:first-child > td {
      border-top-color:#e0e0e0 !important;
   }

   > thead > tr > td:last-child,
   > tbody > tr > td:last-child,
   > tfoot > tr > td:last-child {
      border-right-color:#e0e0e0 !important;
   }

   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td {
      border-top-color:transparent !important;
      border-right-color:transparent !important;
   }

   .table-title {
      width:200px;
      text-align:right;
      padding-right:30px !important;
      background:#ececec !important;
      vertical-align: middle;
   }

   figure {
      width:170px;
      margin:0 auto;
      
   }

}