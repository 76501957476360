.users {
  .list__user li {
    float: left;
    margin: 0 3px 3px 0;
    margin-bottom: 0;
  }
  .list__user li a {
    color: #555;
    border-radius: 4px 4px 0 0;
    background: white;
    padding: 10px 15px;
    display: inline-block;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    border-top: 1px solid #f5f5f5;
    font: 400 14px/20px $font-family--primary;
    &:hover {
      background: #eee;
      color: #555;
    }
  }
  .list__user li.active a {
    color: $white;
    background: $color-base;
    cursor: default;
  }
  .tab-content {
    .tab-pane {
      border-top: 1px solid #eee;
      .form-control {
        box-shadow: none;
        border-radius: 3px;
        height: 38px;
        font-size: 13px;
        color: #555;
      }
    }
  }
  .switch-user {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 39px;
    color: #fff;
    cursor: pointer;
  }
  .switch-user input {
    display: none;
  }
  .slider-switch {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $alternative;
    -webkit-transition: 0.3s;
    transition: 0.4s;
  }
  .slider-switch:before {
    position: absolute;
    content: "";
    height: 80%;
    width: 46%;
    background-color: #fff;
    transition: 0.3s;
    top: 10%;
    left: 4%;
    z-index: 10;
  }
  input:checked + .slider-switch {
    background-color: #505050;
  }
  input:checked + .slider-switch:before {
    left: 51%;
  }
  label.switch-user span {
    position: absolute;
    line-height: 40px;
    width: 50%;
    text-align: center;
    z-index: 1;
  }
  label.switch-user span:first-child {
    right: 5px;
  }
  label.switch-user span:nth-child(2) {
    left: 3px;
  }
  .slider-switch,
  .slider-switch:before {
    border-radius: 20px;
  }
  input.ng-pristine + ul#strength {
    display: none;
  }
  ul#strength {
    display: inline;
    list-style: none;
    margin: 0;
    margin-left: 0px;
    padding: 0;
    vertical-align: 2px;
  }

  .point:last {
    margin: 0 !important;
  }
  .point {
    background: #ddd;
    border-radius: 2px;
    display: inline-block;
    height: 5px;
    margin-right: 1px;
    width: 20px;
  }
  #strength {
    position: relative;
    top: -8px;
  }
  .ng-table {
    thead {
      .ng-table-sort-header {
        background: $color-base;
        color: #fff;
        vertical-align: center;
        .sortable, .header {
          vertical-align: middle;
          .ng-table-header {
            .sort-indicator {
              display: block;
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .dropdown-menu {
            a {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ng-table th.sortable.sort-asc, .ng-table th.sortable.sort-desc {
  background-color: inherit !important;
  text-shadow: none !important;
}

.seleccion-sucursales-usuario-del-master {
  .modal-body {
    .table__base {
      margin-bottom: 0;
    }
  }
}
