@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }
}

// ==========================================================================
// BOTONES PERSONALIZADOS
// ==========================================================================
// TEXT - BACKGROUND - BORDER

.btn-smart {
    @include button-variant($white, $color-base, $color-base);
}
.btn-variant {
    @include button-variant($white, $variant, $variant);
}
.btn-gray {
  @include button-variant($white, $gray-700, $gray-700);
}
.btn-alternative {
    @include button-variant($white, $alternative, $alternative);
}
.btn-favorite{
  @include button-variant($white, $gray-400, $gray-400);
  &.isFaved{
    @include button-variant($white, $red, $red);
  }
}
.btn-simple {
    background: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    &:hover {
        background: transparent;
    }
}
.btn--rounded {
  border-radius: 25px;
  border-width: 2px;
}

.btn--compare{
  position: fixed;
  right: -30px;
  top: 50%;
  transform: rotate(-90deg);
}

.select--product{
  background-color: $color-base;
  border-color: $color-base;
}

.btn-mini{
  font-size: 13px;
  padding: 6px;
}

.btn-pay{
  width: 60%;
  font-size: x-large;
  color: white;
  margin-bottom: 50px;
}
