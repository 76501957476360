.welcome__client {
  font-size: .8em;
  border-right: 1px dotted #c4c4c4;
  margin-right: 0.1em;
  padding: 0.3em 0.8em 0.3em 0em;
}
.welcome__client:last-child {
  border: none;
  padding-right: 0;
}
