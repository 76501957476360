.modal-style {
  border: none !important;
  .shop-modal-header {
    padding: 15px 20px;
  }
  .modal-header {
    background: $color-base;
    .modal-title{
      text-transform: uppercase;
    }
    h5 {
      color: white;
    }
    p {
      font-family: $font-family--primary;
      color: white;
    }
    span {
      display: inline-block;
      width: auto;
    }
  }
  .bg-shop {
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 15px 0;
  }
  .modal-body {
    .product-add {
      label {
        font-size: .9em;
      }
    }
    #resumen-pedido {
      .resumen-producto, .resumen-canasta {
        .cuerpo {
          figure {
            margin: 0 !important;
          }
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
              padding: 8px 15px;
              span {
                text-align: left;
              }
            }
          }
        }
        img {
          display: block;
          width: auto;
          margin: 0 auto;
          max-height: 200px;
          max-width: 100%;
        }
        span {
          float: right;
          font-weight: 400;
        }
        h3 {
          margin-bottom: 10px;
          color: $base-darken;
          text-transform: initial;
          .item-name {
            color: inherit;
            text-align: left;
            background: transparent;
            padding: inherit;
          }
        }
      }
      .resumen-canasta {
        padding: 25px 40px 15px;
      }
    }
    .navbar-search {
      .input-group {
        width: 100%;
      }
    }
    .attr {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px;
      margin-bottom: 0;
      border: 1px solid $gray-200;
      &:hover{
        background: $gray-100;
      }
    }
  }
  .content-modal {
    //overflow-x: hidden;
    //overflow-y: scroll;
    max-height: 600px;
  }
  .precanasta-cantidad label {
    padding-top: 7px;
    font-weight: 600;
  }
  #contact-form {
    .input-group {
      margin-bottom: 15px;
    }
    .input-group-addon {
      padding-left: 15px;
      font-size: 13px;
      min-width: 100px;
    }
    .input-text {
      vertical-align: middle;
    }
    button {
      margin: 5px;
    }
  }
  .shop-modal-footer {
    padding: inherit;
    padding-bottom: 15px;
    text-align: center;
    border: inherit;
  }

}


