
.event_page-title {
   text-align: center;
   font-weight: bold;
   font-size: 30px;
   margin: 0;
}
.events__filters {
   margin: 20px 0 40px 0;
   border-top: 1px solid #eee;
   border-bottom: 1px solid #eee;
   padding: 15px 0;
   display: flex;
   justify-content: space-between;
   .dropdown {
      margin: 0 0 0 20px;
   }
}

.detail-event__title {
   font-weight: bold;
   font-size: 20px;
   color: $color-base;
}

.event-list {
   list-style: none;
   padding: 0px;
   > li {
      margin: 0px 0px 30px;
      position: relative;
      display: flex;
      width: 100%;
      padding: 0px 0 30px;
      border-bottom: 1px solid #eee;
      
      > time {
         color: $color-base;
         text-align: center;
         border-right: 2px solid #ddd;
         width: 140px;
         padding: 0;
         margin: 0px;
         display: flex;
         justify-content: center;
         align-items: center;

         > span {
            display: block;
         }
         > div {
            > .day {
               font-size: 42px;
               font-weight: 100;
               line-height: 1;
            }
            > .month {
               font-size: 37px;
               font-weight: 700;
               line-height: .9;
               letter-spacing: -2px;
            }
         }
      }
      > .info {
         padding: 0px 25px;
         position: relative;
         text-align: left;
         overflow: hidden;
         display: flex;
         flex-direction: column;
         width: 100%;
         &:hover {
            opacity: .7;
            &:after {
               right: -5px;
               opacity: .4;
            }
         }
         &::after {
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            color: #e4e4e4;
            font-size: 72px;
            position: absolute;
            right: 7px;
         }
         a {
            color: inherit;
         }
         
         .title {
            font-size: 21px;
            text-align: left;
            line-height: 1.1;
            padding: 0 0 4px;
            margin: 0;
            font-weight: 600;
            color: $variant;
         }
         .desc {
            margin: 2px 0;
            padding: 0;
         }
      }
   }
}

.detail-event__item {
   border-bottom: 1px solid #eee;
   padding-bottom: 6px;
   margin-bottom: 6px;
   &:last-child {
      border: none;
   }
   & p {
      margin: 0;
   }
   .fa {
      color: #888;
      margin-right: 10px;
      min-width: 15px;
   }
}
.detail-event__paginate {
   display: flex;
   justify-content: space-between;
   margin: 30px 0 20px;
   a {
      color: inherit;
   }
   .arrow {
      font-size: 14px;
      font-weight: lighter;
      display: flex;
      align-items: center;
      margin: 0 20px;
      &:hover .fa {
         color: #aaa;
      }
      .fa {
         font-size: 58px;
         color: #ddd;
      }
   }
}

.detail-event__content {
   padding: 20px;
   border: 1px solid #eee;
   border-radius: 5px;
   margin-top: 5px;
}
