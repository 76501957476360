.company__social {
  @each $colores-social, $bgcolor in $colores-social {
    .#{$colores-social} {
      color: $bgcolor;
      &:hover {
        opacity: .5;
      }
    }
  }
}
