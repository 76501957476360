.toolbox-pagination{
   .view-count-box{
      .pagination-prev, .pagination-items, .pagination-next{
         float: left;
         li {
            &.active{
               a{
                  border: 1px solid $variant;
                  background-color: $variant;
                  color: white;
               }
            }
            a {
               font-size: 0.8em;
               padding-top: 0;
               display: flex;
               justify-content: center;
               align-items: center;
               border: 1px solid $gray-300;
               padding: 6px 12px;
               color: $gray-600;
               &:hover{
                  background-color: $gray-300;
               }
            }
         }
      }
      .pagination-prev{
         li{
            a{
               border-radius: 4px 0 0 4px;
            }
         }
      }
      .pagination-items{
         li{
            float: left;
            a{
               border: 1px solid $gray-300;
               &:hover{
                  background-color: $variant;
                  opacity: 0.5;
                  color: $white;
               }
            }
         }
      }
      .pagination-next{
         li{
            a{
               border-radius: 0 4px 4px 0;
            }
         }
      }      
   }
}
