.company__user-top {
  .company__item {
    margin-right: .1em;
    border-right: 1px dotted $gray-400;
    padding-right: .6em;
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
}

.item-account {
  cursor: pointer;
  .icon_account {
    color: white;
    .avatar img {
      width: 50px;
      height: auto;
      box-shadow: 0 0 0 4px $gray-400;
      margin: 4px;
      .media-body {
        color: initial;
      }
    }
  }
  .account__content {
    width: 320px;
    padding: 15px;
    top: 29px;
    line-height: normal;
    .media-heading {
      color: $gray-800;
    }
    .account-icons {
      display: flex;
      flex-wrap: wrap;
      > .account__box-icon {
        flex-basis: 33.3%;
        display: flex;
        margin: 10px 0;
        justify-content: center;
        &:hover {
          transform: scale(1.1);
        }

        a {
          color: $gray-800;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: none;
          &:hover{
            color: $variant;
            transition: none;
          }
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}
