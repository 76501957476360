.company__info-top {
  .company__item {
    margin-right: .1em;
    border-right: 1px dotted $gray-500;
    padding-right: .6em;
    p {
      margin: 0;
    }
    &:last-child {
      border: none;
      padding-right: 0;
    }
  }
}
