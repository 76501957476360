.b2b-reports {
  margin-bottom: 15px;
  .consulta-estado-remisiones{
    font-size: 0.8em
  }
  .ng-table-sort-header {
    background: $color-base;
    color: #fff;
    th {
      text-align: center;
      vertical-align: middle;
    }
  }
  .table-condensed > tbody > tr > td {
    text-align: center;
    vertical-align: middle;
  }
  .ng-table-sort-header .sort-indicator {
    font-weight: 600;
    display: block;
    text-align: center;
    padding: 5px 0px;
  }
  .datos-del-detalle{
    font-size: 0.8em
  }
}
