// ==========================================================================
// Configuracion de la tipografia
// ==========================================================================

@if variable-exists(font-url--google) {
  @import url($font-url--google);
}

@mixin font-weight($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

.w100 {
  @include font-weight(thin);
}

.w200 {
  @include font-weight(extra-light);
}

.w300 {
  @include font-weight(light);
}

.w400 {
  @include font-weight(book);
}

.w500 {
  @include font-weight(medium);
}

.w600 {
  @include font-weight(semi-bold);
}

.w700 {
  @include font-weight(bold);
}

.w800 {
  @include font-weight(extra-bold);
}

.w900 {
  @include font-weight(ultra-bold);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family--title;
}

$start: 23pt;
@each $h in h1, h2, h3, h4, h5, h6 {
  #{$h} {
    font-size: $start;
  }
  $start: $start - 2;
}

.h1 {
  font-size: 23pt;
}

.h2 {
  font-size: 21pt;
}

.h3 {
  font-size: 19pt;
}

.h4 {
  font-size: 17pt;
}

.h5 {
  font-size: 15pt;
}

.h6 {
  font-size: 13pt;
}

.p {
  font-size: $font-size;
}

body, p {
  font-family: $font-family--primary;
}

body {
  color: $text-color;
  font-size: $font-size;
}

p {
  font-size: $font-size;
  line-height: 1.4;
}

a {
  color: $color-base;
  &:hover {
    color: $base-lighten;
  }
}

label {
  font-weight: inherit !important;
  font-size: $font-size !important;
}

.btn {
  font-size: $font-size;
}

.dropdown-menu {
  font-size: $font-size;
}

table {
  font-size: $font-size;
}
