// -- Box Product -- //
.box-product{
   position: relative;
   &__badges{
      position: absolute;
      top: 5px;
      left: 5px;
      .box-product__badge{
         padding: 4px 7px;
         color: $white;
         display: block;
         margin-bottom: 2px;
         text-align: center;
         &--new{
            background-color: $color-base;
         }
         &--discount{
            background-color: $variant;
         }
         &--offert{
            background-color: $alternative;
         }
      }
   }
   &__feature{
      position: absolute;
      top: 0;
      right: 0;
      color: $white;
      &:after{
         content: "";
         position: absolute;
         top: 0;
         right: 0;
         width: 0;
         height: 0;
         border-top: solid 49px #ff6d00;
         border-left: solid 40px transparent;
      }
      i{
         position: relative;
         z-index: 2;
         margin-right: 6.5px;
         margin-top: 6.5px;
      }
   }
   &__image{
      border: $gray-200 solid 1px;
      height: 240px;
      margin-bottom: 15px;
      img{
         width: 100%;
         height: 100%;
         object-fit: contain;
      }
   }
   &__info{
      min-height: 76px;
      .box-product__name{
         margin: 0;
         a{
            color: $gray-800;
            &:hover{
               color: $color-base;
            }
         }
      }
      .box-product__exclusive{
         color: $color-base;
      }
      .box-product__rating{
         i{
            font-size: 13pt;
         }
      }
   }
   &__price{
      margin-bottom: 10px;
      display: flex;
      small{
         color: $color-base;
      }
      span{
         display: block;
         margin: 0;
      }
      &-before{
         margin-right: 15px;
         span{
            text-decoration: line-through;
            color: $gray-600;
         }
      }
      .box-product__no-price{
         color: $variant;
      }
   }
   &__actions{
      display: flex;
      margin-bottom: 30px;
      li{
         margin-right: 3px;
      }
   }
}
// Vistas Product List
.vista-1{
   .box-product{
      &__feature{
         left: 178px;
         right: inherit;
      }
      &__image{
         height: 194px;
         margin-bottom: 30px;
         width: 25%;
         float: left;
         margin-right: 30px;
      }
      &__info{
         padding-top: 15px;
      }
   }
}
.vista-2{
   .box-product{
      &__image{
         height: 375px;
      }
      &__info, &__price, &__actions{
         text-align: center;
         justify-content: center;
      }
   }
}
