.product__info {
  .badges {
    .badge {
      border-radius: 0px;
      margin-bottom: .7em;
      &--discount {
        background-color: $red;
      }
      &--new {
        background-color: $alternative;
      }
      &--exclusive {
        background-color: $base-lighten;
      }
      &--feature {
        background-color: $variant;
      }
    }
  }
  .item__name {
    margin: 0 0 .5em;
    color: inherit;
  }
  .ratings__container {
    display: flex;
    align-items: center;
    .ratings {
      margin: 0 2em 0 0;
      &.ratings__starts {
        .star-button {
          display: inline-flex;
          color: $yellow;
          cursor: pointer;
        }
      }
      &.ratings__reviews {
        color: $gray-600;
      }
    }
  }
  .price {
    .attribute__value {
      .sub {
        color: #aeaeae;
        font-weight: 400;
        text-decoration: line-through;
      }
    }
  }
  .wishlist--like {
    .like-icon {
      color: #bdbdbd;
    }
  }
  .item__description {
    margin: .3em 0;
  }
  .info__attributes {
    max-height: 280px;
    overflow: hidden;
    transition: none;
    &:hover {
      overflow: auto;
      margin-right: -17px;
    }
    .info__attributes-list {
      margin: .5em 0;
      .attributes-list__item {
        border-bottom: 1px solid $gray-200;
        padding: .4em 0;
        display: flex;
        align-items: center;
        .attribute__name {
          width: 50%;
          line-height: 1.2;
        }
        .attribute__value {
          width: 50%;
          margin: 0;
        }
      }
    }
  }
  .info__price {
    &-list {
      .attributes-list__item {
        padding: .5em 0;
        display: flex;
        align-items: center;
        position: relative;
        &.procedure {
          display: block;
        }
        .attribute__name {
          width: 50%;
          font-weight: 600;
          line-height: 1.2;
        }
        .attribute__value {
          color: #999;
          width: 70%;
          font-weight: 400;
          margin: 0;
          &.select {
            width: 100%;
          }
        }
        &.price--current,
        &.price--normal,
        &.price--value {
          .attribute__value {
            color: $color-base;
          }
          .sub-price {
            font-weight: 100;
            color: #999;
            font-size: 60%;
            letter-spacing: 0;
          }
          .info-iva {
            background: rgba(0, 0, 0, 0.5);
            padding: .6em 1em .5em;
            font-size: 10px;
            letter-spacing: 1px;
            border-radius: 25px;
            color: #fff;
            font-weight: 100;
            position: relative;
            top: -3px;
          }
        }
        &.price--normal {
          .tached {
            color: #afafaf;
            font-weight: 500;
            font-size: 1.3em;
            text-decoration: line-through;
          }
        }
        .item__product-add {
          display: flex;
          align-items: center;
          .product-add__quantity {
            position: relative;
            margin-right: 5px;
            .quantity-btn {
              font-size: .83em;
              color: white;
              position: absolute;
              top: 3px;
              text-align: center;
              cursor: pointer;
              background: $variant;
              width: 1.1em;
              right: 5px;
              line-height: 1.2;
              height: 1.1em;
              border-radius: 50%;
              &:hover {
                background: darken($variant, 20%);
              }
              &.quantity--up {
                left: inherit;
              }
              &.quantity--down {
                right: 5px;
                top: 20px;
              }
            }
            .quantity-input {
              border-radius: 6px;
              background-color: $gray-200;
              line-height: 32px;
              padding-right: 1em;
              font-weight: 500;
              text-align: center;
              color: $text-color;
              border: none;
              width: 130px;
              height: 37px;
              padding-left: .5em;
              font-size: 1.2em;
            }
          }
        }
        .wishlist {
          @include m(like) {
            position: relative;
            .wishlist-icon {
              border: 1px solid $gray-400;
              background: $gray-400;
              border-radius: 4px;
              line-height: 2.4em;
              width: 2.3em;
              height: 2.3em;
              display: inline-flex;
              justify-content: center;
              align-items: center;
            }
            .like-icon {
              color: #fff;
              &.isFaved {
                background: $red;
                border: solid 1px $red;
              }
            }
          }
        }
      }
    }
    &-notavailable {
      font-size: .9em;
      color: $alternative;
      border: 1px dashed;
      padding: .3em;
      text-align: center;
      margin: .5em 0;
    }
    &-contact {
      margin: .5em 0;
    }
  }
  .social__shared {
    display: flex;
    align-items: center;
    margin: 1.5em 0;
    justify-content: flex-start;
    font-size: .9em;
    color: #888;
    .shared-list {
      display: flex;
      align-items: center;
      border-right: 1px solid #eeeeee;
      padding-right: .7em;
      width: inherit;
      margin: 0;
      .shared__item {
        margin: 0 .1em;
        width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        .item-link {
          width: 2em;
          height: 2em;
          background: #b0b0b0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          cursor: pointer;
          &.facebook {
            background-color: #3b5998;
          }
          &.googleplus {
            background-color: #dd4b39;
          }
          &.instagram {
            background-color: #517fa4;
          }
          &.linkedin {
            background-color: #007bb6;
          }
          &.pinterest {
            background-color: #cb2027;
          }
          &.twitter {
            background-color: #00aced;
          }
          &.youtube {
            background-color: #b00;
          }
          &.soundcloud {
            background-color: #f60;
          }
        }
      }
    }
    .ratings {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .addreviews {
        color: #fff;
        background-color: $alternative;
        padding: .8em;
        &:hover {
          background-color: rgba($alternative, 0.9);
        }
      }
    }
    .recommend {
      &-link {
        background: #4c4c4c;
        padding: .4em;
        border-radius: 3px;
        width: 26px;
        height: 26px;
        color: #fff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

a#fav {
  display: block;
  width: 39px;
  height: 37px;
  overflow: hidden;
  background: url(../img/sprites/sprite-fav.png);
}

a#reload {
  display: block;
  width: 39px;
  height: 37px;
  overflow: hidden;
  background: url(../img/sprites/sprite-reload.png);
}
