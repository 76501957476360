.confirmation-order {
  border: 1px dashed $gray-300;
  margin: 1em auto 3em;
  padding: 2em 3em;

  .text-welcome__title {
    margin: 1em 0;
  }
  .table > tbody > tr > td {
    font-size: .9em;
    line-height: 1.2;
  }
  .table, .line-table {
    width: 50%;
    margin: 1.5em auto;
  }
}