
.icon--input {
  position: relative;
  .icon {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 20px;
    text-align: center;
    &.mini {
      left: 25px;
    }
    + .input-icon {
      box-shadow: none;
      border-color: #eaeaea;
      background: #f9f9f9;
      padding: 10px 12px 10px 36px;
      height: auto;
      &:focus {
        border-color: #dbdcdc;
      }
      &::placeholder,
      &::-webkit-input-placeholder {
        font-size: .9em;
      }
    }
  }
}

.input-help {
  font-size: .8em;
  .help-block {
    color: red;
  }
}

.custom-quantity-input {
  float: left;
  display: block;
  position: relative;
  height: 32px;
  margin-top: -9px;
  input {
    border: none;
    width: 110px;
    padding-right: 1.5em;
    font-size: 1em;
    height: 38px;
    text-align: center;
    background: $gray-200;
    border-radius: 9px;
  }
  .quantity-btn:hover {
    background: rgba($variant,0.9);
  }
  .quantity-btn {
    cursor: pointer;
    font-size: .8em;
    background: $variant;
    color: white ;
    top: 4px;
    width: 14px;
    right: 5px;
    line-height: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
  }
  .quantity-btn.quantity-input-down {
    top: 21px;
  }
}

.ubicaciones-texto {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-top: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  .ubicacion-texto {
    padding: 7px 10px;
    cursor: pointer;
    font-size: 11px;
    border-bottom: 1px solid #eaeaea;
    text-transform: uppercase;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #f9f9f9;
    }
  }
}

.select-dropdown:hover .dropdown-toggle {
  border-color: $base-darken;

}

.btn-group:hover .dropdown-menu {
  display: block;
}

.select-dropdown .dropdown-menu {
  z-index: 901;
}

.dropdown-menu li a:hover, .dropdown-menu li a:hover a {
  color: #fff !important;
  background: $color-base !important;
  border-radius: 0 !important;
  border-radius: 0;
  font-weight: 400;
}

.star-button.star-on .material-icons {
  color: #FFC107;
}

.star-button.star-off .material-icons {
  color: #dcdcdc;
}

.input-date {
  padding: 4px 0px 0px 36px !important;
}