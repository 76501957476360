.testimonies {
  background: $color-base;
  padding: 4em 0;
  &-items-container {
    display: flex;
    align-items: center;
    .carousel-controls {
      background: transparent;
      width: initial;
      margin: 0;
      padding: 0;
      top: 0;
      height: inherit;
      position: relative;
    }
    .item__inner {
      .testimonies__item-img {
        img {
          width: 130px;
          height: 130px;
          margin-bottom: 1.5em;
        }
      }
      .testimonies__item-description {
        color: #fff;
        padding: 0 1em;
        line-height: 1.2;
      }
    }
  }
  &.bg--white {
    background: none !important;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 2em 0 1.5em;
    > .container {
      width: initial;
    }
    .testimonies-items-container {
      .carousel-controls {
        .carousel-btn {
          width: 1.3em;
          height: 1.3em;
          border-radius: 0;
          font-size: .9em;
          color: #ffffff;
          background: $variant;
          border: none;
          &:hover {
            background: darken($variant, 10%);
          }
        }
      }
      .item__inner {
        .testimonies__item-description {
          color: #434343;
        }
      }
    }
  }
}


