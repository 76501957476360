.page-tunnel{
  .clear {
    clear: both;
    height: 2px;
  }
  .title {
    margin-bottom: 15px;
    font-family: $font-family--title;
    font-size: 21px;
  }
  .xs-margin {
    margin-bottom: 10px;
  }
  .tunnel_new {
    > [class*='col-'].col-info {
      display: flex;
      flex-direction: column;
      border-left: 1px solid #ddd;
    }

    > [class*='col-'].col-info:last-child {
      border-right: 1px solid #ddd;
    }
    .panel-default {
      border: none;
      margin-bottom: 0;
      box-shadow: none;
    }
    .panel-mini{
      border-color: #ececec;
    }
    .panel-heading {
      color: #FFF;
      background: rgb(59,149,224);
      background: -webkit-linear-gradient(left, $base-lighten 1%,$color-base 100%);
      background: linear-gradient(to right, $base-lighten 1%,$color-base 100%);
      padding-left: 15px;
      border-radius: 0;
    }
    .panel-body {
      padding: 15px;
    }
    .resumen-canasta {
      .form-group {
        font: 13px/1.5 'Open Sans', sans-serif;
        margin-bottom: 2.3px!important;
      }
    }
    .ads-tunel .panel {
      min-height: 278px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border: 1px solid #dee2e6 !important;
    }
    .custom-checkbox {
      padding-left: 30px;
      margin-bottom: 0px;
      margin-top: 5px;
      margin-bottom: 0px;
      margin-top: 5px;
      input[type="checkbox"] {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        opacity: 0;
        z-index: 100;
      }
      .checbox-container {
        position: absolute;
        left: 0;
        top: 2px;
        display: inline-block;
        border: 1px solid #c3c3c3;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        background: #fcfcfc;
        text-align: center;
        line-height: 14px;
      }
      .checbox-container i {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        opacity: 0;
      }
      input[type="checkbox"]:checked + .checbox-container i {
        opacity: 1;
      }
      input[type="checkbox"]:checked + .checbox-container {
        background: #78b829 !important;
        border-color: #78b829;
      }

    }
    #policies {
      cursor: pointer;
    }
    .control-label {
      padding: 4px 0;
      color: #505050;
      font: 600 13px/1.5 'Open Sans', sans-serif;
      margin-bottom: 0;
      text-align: left;

    }
    .destacar {
      color: #008533;
      font-size: 15px;
    }
    .form-control {
      height: 33px;
      padding: 7px 12px;
      font-size: 13px;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 2px;
      border-radius: 3px;
      border-color: #dcdcdc;
    }
    .form-group {
      margin-bottom: 5px!important;
    }
    .resumen-canasta .form-control-static {
      border-bottom: 1px dashed #d4d3d3;
      text-align: right;
      p{
        margin: 0 0 10px;
        font-size: 13px;
      }
    }

    .form-control-static {
      padding-top: 4px;
      padding-bottom: 4px;
      min-height: auto;
      line-height: 2;
    }
  }
  .pasos-pedido {
    .panel-body {

      padding-top: 15px;
    }
  }
  #content-terminos-condiciones {
    border-radius: 0;
    height: 162px;
    margin-bottom: 20px;
    overflow: auto;
    padding: 0px 10px;
    width: 100%;
  }
}
.help-block {
  font-size: 11px;
  color: #c72928;
}
