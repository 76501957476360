.grid-products {
  &.related__widget {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .grid-products__heading {
      width: 100%;
      padding: .9em 1em;
      border-bottom: 4px solid $gray-200;
      .title__text {
        margin: 0;
        color: white;
        &--line:after {
          margin: .7em 0;
        }
      }
      .carousel-controls {
        top: -3px;
        right: -65px;
        background: transparent;
        .carousel-btn {
          width: 20px;
          border: 0;
          background: transparent;
          color: $white;
          box-shadow: none;
        }
      }
    }
    .grid-products__items {
      .item-product {
        padding: 0px;
        .related-product {
          border-bottom: 1px dashed $gray-300;
          padding-bottom: 1em;
        }
        .item__image {
          display: flex;
          justify-content: center;
          height: 98px;
          padding: 0px;
          .badges {
            right: 0;
          }
          .image__container {
            max-height: 96px;
            min-height: 96px;
            margin: 0;
            .item-image {
              max-height: 96px;
            }
          }
        }
        .item__name {
          justify-content: flex-start;
          .item__name_title {
            text-align: left;
            margin-top: 0;
          }
          .jk-rating-stars-container {
            .star-button {
              .material-icons {
                font-size: 1.4em;
              }
            }
          }
        }
        .item__price {
          .item__price-list {
            .list-inline-item {
              justify-content: flex-start;
            }
          }
        }
        .clean-box {
          box-shadow: none;
        }
        &:hover {
          .image__container {
            .item-image {
              visibility: visible;
              opacity: 1;
              display: block !important;
            }
          }
        }
      }
      div {
        &[class*="owl-carousel"] {
          border: 0;
          [class*="owl-wrapper"] {
            display: flex !important;
            [class*="owl-item"] {
              box-shadow: none;
              [class*="col-"] {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
