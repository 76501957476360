.aside__filters {
  .widget__aside {
    .widget__accordion {
      .panel-group {
        margin-bottom: 0;
        .panel {
          border: none;
          margin: 0;
          background: transparent;
          box-shadow: none;
          .panel-heading {
            padding: .8em 0;
            border-radius: 0;
            .panel-title {
              letter-spacing: 0;
              font-size: $font-size;
              font-family: $font-family--primary;
              a {
                color: $text-color;
              }
              .accordion__btn {
                padding-right: .3em;
                color: $gray-400;
              }
            }
            &:hover {
              background: rgba($gray-500, .2);
            }
          }
          .panel-collapse {
            .panel-body {
              border-top: none !important;
              padding: 0;
              .submenu-vertical {
                border: 0;
                margin: 0;
                .panel-body {
                  a {
                    color: $gray-600;
                  }
                  .panel-group {
                    .panel-heading {
                      border-bottom: 1px dashed $gray-300;
                      .accordion-toggle {
                        .header__title {
                          margin-left: 10px;
                          //&:before {
                          //  content: "\f105";
                          //  font-family: FontAwesome;
                          //  font-size: 13px;
                          //  position: absolute;
                          //}
                          a {
                            div {
                              margin-left: 10px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .accordion-toggle {
          > span {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .accordion__more, .accordion__less {
          background: $variant;
          @include margin-padding(all, 0, all, .7em .9em);
          font-size: .9em;
          color: $gray-100;
          cursor: pointer;
          border-radius: 0 0 10px 10px;
          text-align: center;
        }
      }
    }
  }
}
