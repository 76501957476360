.header__favorite {
  position: relative;
  a {
    color: $gray-800;
    &:hover {
      color: $variant;
    }
  }
  .badge {
    position: absolute;
    top: -16px;
    color: white;
    background: $color-base;
    width: 23px;
    height: 23px;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
    left: 25px;
    font-size: .9em;
  }
}
