.comments__reviews {
   &-title {
     padding: .9em 1em;
     color: white;
   }
   .sort__filter {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
      .label-sort {
         font-size: 1em;
         font-weight: 600;
         display: flex;
         align-items: center;
      }
      .sort__arrow {
         font-size: 1.3em;
         letter-spacing: -2px;
         position: relative;
         left: 4px;
         top: 8px;
         cursor: pointer;
         span {
            color: $gray-500;
            &:hover, &.active {
               color: $variant;
            }
         }
      }
   }
   .sort__comment {
      padding: 1.5em 0;
      border-bottom: 1px solid #eaeaea;
      .rate_comment {
         display: flex;
         .star-button {
            display: inline-flex;
            color: #fec01a;
            cursor: pointer;
            i {
               font-size: 18px;
            }
         }
      }
      .comment__title {
         font-weight: 700;
      }
      .comment__description,
      .comment__user {
         font-size: .9em;
         color: #7f7f7f;
      }
   }
   .comments-container {
      &.effects {
         position: relative;
         &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 9;
            bottom: 0;
            left: 0;
         }
      }
   }
}


.modal-style .acciones-opinion {
   display: flex;
   flex-direction: column;
   text-align: center;
   margin-top: 10px;
   justify-content: center;
}
.modal-style .jk-rating-stars-container {
   text-align: center;
   display: flex;
   justify-content: center;
}
