.table__base {
  .table-title {
    vertical-align: middle;
  }
  .head__table-title {
    background: darken(white, 65%);
    color: white;
  }

  .table__base-rows {
    .table__base-cells {
      vertical-align: middle;
      border: 0;
      a {
        color: $text-color;
      }
      &.price {
        font-size: inherit;
      }
    }
  }
  &.border {
    .table__base-rows {
      .table__base-cells {
        border: 1px solid #ddd;
      }
    }
  }
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: transparent;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #f7f7f7;
}

.table-striped > tbody > tr:nth-child(even) > td.table-title,
.table-striped > tbody > tr:nth-child(even) > th.table-title {
  background-color: #e5e5e5 !important;
}

.img-resumen-cart {
  width: 60%;
  margin: 0 auto 20px;
}

table {
  vertical-align: middle;
  thead {
    font-family: $font-family--title;
    border-top: 1px solid #ddd;
    text-transform: uppercase;
  }
  tbody {
    color: #565656;
    .pt2 {
      padding-top: 2px !important;
    }
  }
  &.resumen-pedido {
    tbody {
      > tr {
        > td {
          padding: 3px 5px;
          line-height: 1.3;
          border-top: 1px solid #ececec;
        }
      }
    }
  }
}
