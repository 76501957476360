.header__cart {
  .dropdown-cart {
    .cart-menu-icon {
      .icon-basket {
        color: $gray-800;
        font-size: 27px;
        &:hover {
          color: $variant;
        }
      }
    }
  }
  .dropdown-cart__menu {
    .dropdown-cart {
      display: flex;
      justify-content: flex-end;
    }
    .dropdown-cart__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      position: relative;
      padding: 0;
      .cart-menu-icon {
        padding: 0;
        color: initial;
        text-align: center;
        margin-right: 10px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          content: none;
          border-top: .3em solid;
          border-right: .3em solid transparent;
          border-bottom: 0;
          border-left: .3em solid transparent;
          color: $alternative;
          position: absolute;
          bottom: -0.1em;
        }
      }
      .dropdown-cart__btn__inner {
        display: flex;
        flex-direction: column;
        .items-cant {
          color: white;
          background: $color-base;
          font-weight: 700;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          position: absolute;
          top: -16px;
          left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: .9em;
        }
        .drop-price {
          display: block;
          color: #919191;
          font-size: 0.8em;
          font-weight: 400;
          text-align: left;
        }
      }
    }
    &:hover .dropdown-menu {
      display: block;
    }
    .dropdown-menu {
      border-top: 3px solid $variant;
      background: $white;
      padding: 15px;
      min-width: 320px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
      border-radius: 0;
      z-index: 1000;
      > div[ng-if^="cart"] {
        min-width: 300px;
      }
      > div[ng-if^="!"] {
        min-width: 184px;
        font-size: .7em;
        text-align: center;
        line-height: 0px;
        padding: 0;
        margin: -10px -5px;
        position: relative;
        top: 10px;
      }
      .dropdown-cart-description {
        margin: 0 10px;
        line-height: 0;
        color: #7b7b7b;
      }
      .cart-resume {
        overflow-y: hidden;
        margin-top: 10px;
        border-bottom: 1px solid #CCC;
        &.cart-resume-1-item {
          max-height: 100px;
        }
        &.cart-resume-2-item {
          max-height: 190px;
        }
        &.cart-resume-3-item {
          max-height: 260px;
        }
        .dropdown-cart-product-list {
          margin-bottom: 5px;
          list-style-type: none;
          .item {
            position: relative;
            margin-bottom: 10px;
            padding: 5px 0;
            border-bottom: 1px dashed #efefef;
            .delete-item,
            .edit-item {
              font-size: 14px;
              display: inline-block;
              position: absolute;
              top: 0;
              color: #828886;
              font-weight: 300;
              right: 15px;
            }
            figure {
              overflow: hidden;
              height: 45px;
              width: 55px;
              float: left;
              margin: 0;
              margin-right: 10px;
              a {
                height: 100%;
                position: relative;
                display: inline-block;
                width: 100%;
              }
              img {
                max-width: 100%;
                display: block;
                height: auto;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                max-height: 85px;
              }
            }
            .dropdown-cart-details {
              float: left;
              width: 190px;
              padding-right: 5px;
              font-size: .9em;
              .item-name {
                line-height: 15px;
                margin-bottom: 5px;
                font-size: 1em;
                font-weight: 700;
                display: inline-block;
                margin: 0;
                text-align: left;
                a{
                  &:hover{
                    font-weight: 700;
                  }
                }
              }
              .item-price {
                font-size: 14px;
                color: #19232d;
                font-weight: 700;
                padding-left: 1px;
              }
            }
          }
        }
      }
      .dropdown-cart-total {
        width: 100%;
        margin: 0;
        margin-top: 10px;
        li {
          font: 600 15px/18px $font-family--primary;
          color: $color-base;
          margin-bottom: 6px;
          .dropdown-cart-total-title {
            text-transform: uppercase;
            color: #575a59;
            margin-right: 4px;
            font-size: 14px;
          }
          .sub-price {
            font-size: 75%;
            position: relative;
            bottom: 0;
          }
        }
      }
    }
  }
}
