.header__zone3 {
  display: flex;
  border-bottom: 1px solid $gray-100;
  @include margin-padding(all, 0, all, 1em 0);
  .icon-bar-b2b {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    .icons-header {
      .header__user {
        .item__link {
          display: flex;
          align-items: center;
          flex-direction: row;
          cursor: pointer;
          color: $gray-800;
          transition: none;
          &:hover {
            color: $variant;
          }
        }
        &.item-account {
          vertical-align: top;
        }
      }
    }
  }
}

.btn--date {
  position: fixed;
  top: 30vh;
  z-index: 1;
  margin-top: 47px;
  right: -47px;
  transform: rotate(-90deg);
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  &-open {
    right: 225px;
    margin-top: 48px;
  }
}

.form-date {
  background: white;
  border: 1px solid #dee2e6;
  position: fixed;
  top: 30vh;
  z-index: 999;
  right: -279px;
  transition: all linear .1s;
  &.form-date--open {
    right: 0;
    margin-top: 0;
  }
  .border {
    border: 0;
  }
  .txt-base {
    color: $variant;
  }

  .icon-form {
    height: 100%;
    background: $variant;
    top: 0 !important;
    width: 40px !important;
    padding-top: 5px;
    left: -10px !important;
    border-radius: 10px;
  }
  .box__card {
    padding: 2em;
    border-radius: 12px;
  }
  .btn-radius {
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

}
