.menu__header {
  &.v2 {
    .megamenu__ads {
      .megamenu__list {
        li {
          a {
            border-bottom-width: 0px;
          }
        }
        .bloque-ads {
          padding: 5px;
          .link__a-img {
            a {
              padding: 10px 0;
            }
          }
          .link-img {
            width: 100%;
            border-bottom-width: 0px;
            background: inherit !important;
            &::after {
              content: "";
            }
            .img-block {
              max-width: 100%;
              display: block;
            }
          }
          .megamenu__block {
            a {
              &:hover {
                background: $color-base !important;
                color: $white !important;
              }
            }
            .megamenu__item {
              &::after {
                content: "";
              }
              display: flex;
              align-items: center;
              font-size: 12px;
              text-align: left;
              padding: 5px;
              background: inherit !important;
              width: 100%;
              height: 38px;
              border-bottom-width: 0px;
            }
          }
        }
      }
    }
  }
}