.text-welcome {
  text-align: center;
  margin: .5em auto .8em;
  @include e(title) {
    font-family: $font-family--title;
    font-weight: 700;
    font-size: 1.6em;
    letter-spacing: -.5px;
    color: $base-darken;
    margin-bottom: 0;
  }
  @include e(description) {
    line-height: 1.5;
  }

  &.v1 {
    > div {
      width: 70%;
      margin: 2.5em auto;
      @media (max-width: $screen-sm-max) {
        width: 93%;
      }
    }

    .text-welcome__title {
      font-weight: 700;
      margin-bottom: .6em;
      font-size: 2em;
      @media (max-width: $screen-sm-max) {
        font-size: 1.5em;
      }
    }
  }
  &.v2 {
    text-align: left;
    > div {
      margin: 1em auto 2em;
      @media (max-width: $screen-sm-max) {
        width: 93%;
      }
    }

    .text-welcome__title {
      font-weight: 700;
      margin-bottom: 1em;
      font-size: 27px;
      text-align: center;
      @media (max-width: $screen-sm-max) {
        font-size: 1.5em;
      }
    }
  }
}
