.header__zone2 {
    background: $gray-100;
  .seleccionar-co {
    a {
      color: $gray-800;
      cursor: pointer;
      &:hover{
        color: $color-base;
      }
    }
  }
}
