$color-base: #114c87;$variant: #ffcc00;$alternative: #114c87;$complement: complement($color-base);$text-color: #424242;$font-url--google: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap';$font-family--primary: 'Montserrat', sans-serif;$font-family--secondary: 'Montserrat', sans-serif;$font-family--title: 'Montserrat', sans-serif;// ==========================================================================
// Bootstrap Sass
// ==========================================================================
@import "bootstrap";
// ==========================================================================
// Config Site
// ==========================================================================
@import "config/config";
// ==========================================================================
// Base Site
// ==========================================================================
@import "base/base";
// ==========================================================================
// Helper
// ==========================================================================
@import "helpers/helpers";
// ==========================================================================
// Layout¡
// ==========================================================================
@import "layout/layout";
// ==========================================================================
// Modules Site
// ==========================================================================
@import "modules/modules";
// ==========================================================================
// paginas (no modularizadas)
// ==========================================================================
@import "pages/pages";
// ==========================================================================
// Skins Site
// ==========================================================================
@import "theme/theme";